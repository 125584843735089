define("ember-svg-jar/inlined/pull-icon-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"44.25\" cy=\"44.25\" r=\"42.705\" fill=\"#fff\" stroke=\"#edeeef\" stroke-width=\"3\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"/><circle cx=\"25.734\" cy=\"30.739\" r=\"1.155\" fill=\"#fff\"/><circle cx=\"29.147\" cy=\"30.739\" r=\"1.155\" fill=\"#fff\"/><circle cx=\"32.559\" cy=\"30.739\" r=\"1.155\" fill=\"#fff\"/><g fill=\"none\" stroke=\"#65babe\" stroke-width=\"2.222\" stroke-miterlimit=\"10\"><path stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M20.85 26.35v10.862l-7.316 6.351 7.316 6.351v10.862h49.279V26.35zM29.799 46.662h29.232M29.799 52.193H46.76\"/><g transform=\"translate(-6.051 -11.143)\"><path stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M35.85 47.355l2.271 2.355 3.626-5.05\"/><ellipse cx=\"48.74\" cy=\"47.355\" rx=\"2.64\" ry=\"2.695\"/><g stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M55.275 44.66v5.39M58.292 44.66l-3.017 3.489M58.732 50.05l-2.351-3.181\"/></g></g></g>",
    "attrs": {
      "viewBox": "0 0 88.5 88.5",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});