define("ember-svg-jar/inlined/icon-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"11.53\" cy=\"11.53\" r=\"8\" fill=\"none\" stroke=\"#9da2a7\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"/>a<g fill=\"none\" stroke=\"#9da2a7\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"><path d=\"M11.53 7.53v6.74M14.48 12.03l-2.95 2.95-2.95-2.95\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24.34 23.37"
    }
  };
});