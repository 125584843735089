define("ember-svg-jar/inlined/info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"none\" stroke=\"#3eaaaf\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\" d=\"M9.4 12.8V8.7\"/><path d=\"M9.401 5.4L9.4 5.399\" fill=\"none\" stroke=\"#3eaaaf\" stroke-width=\"1.75\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"/><circle cx=\"9.4\" cy=\"8.6\" r=\"7\" fill=\"none\" stroke=\"#3eaaaf\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 18.6 18"
    }
  };
});