define("ember-svg-jar/inlined/icon-stopwatch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle class=\"icon-stopwatch_svg__st0\" cx=\"7.369\" cy=\"2.173\" r=\"1.583\"/><path class=\"icon-stopwatch_svg__st0\" d=\"M15.356 9.393c-.005.003-.011.001-.014-.004l-1.269-2.407c-.003-.005-.001-.011.004-.013l1.534-.799c.005-.003.011-.001.014.004l1.269 2.407c.003.005.001.011-.004.013l-1.534.799z\"/><circle class=\"icon-stopwatch_svg__st0\" cx=\"7.369\" cy=\"12.146\" r=\"6.757\"/><path class=\"icon-stopwatch_svg__st0\" d=\"M6.982 8.256v3.834l2.542 1.708\"/>",
    "attrs": {
      "viewBox": "0 0 17.5 19.5"
    }
  };
});