define("ember-svg-jar/inlined/icon-log-scan-passed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<linearGradient id=\"icon-log-scan-passed_svg__a\" x2=\"0\" y2=\"100%\"><stop offset=\"0\" stop-color=\"#bbb\" stop-opacity=\".1\"/><stop offset=\"1\" stop-opacity=\".1\"/></linearGradient><rect rx=\"3\" width=\"110\" height=\"20\" fill=\"#555\"/><rect rx=\"3\" x=\"57\" width=\"53\" height=\"20\" fill=\"#4c1\"/><path fill=\"#4c1\" d=\"M57 0h4v20h-4z\"/><rect rx=\"3\" width=\"110\" height=\"20\" fill=\"url(#icon-log-scan-passed_svg__a)\"/><g fill=\"#fff\" text-anchor=\"middle\" font-family=\"DejaVu Sans,Verdana,Geneva,sans-serif\" font-size=\"11\"><text x=\"29.5\" y=\"15\" fill=\"#010101\" fill-opacity=\".3\">log scan</text><text x=\"29.5\" y=\"14\">log scan</text><text x=\"82.5\" y=\"15\" fill=\"#010101\" fill-opacity=\".3\">passing</text><text x=\"82.5\" y=\"14\">passing</text></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "110",
      "height": "20"
    }
  };
});