define("ember-svg-jar/inlined/icon-encircled-help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" stroke=\"#666\"><circle cx=\"7.5\" cy=\"7.5\" r=\"7\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M5.52 5.93a1.927 1.927 0 011.96-2.11 1.73 1.73 0 011.14 3.22 2.467 2.467 0 00-1.09 1.98\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><circle cx=\"7.5\" cy=\"10.95\" r=\".25\" stroke-miterlimit=\"10\"/></g>",
    "attrs": {
      "viewBox": "0 0 15 15",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});