define("ember-svg-jar/inlined/flash-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#db4545\"><path d=\"M19.7 17.3c-.3 0-.6-.1-.9-.4L9.9 7.8c-.5-.5-.5-1.3 0-1.8s1.3-.5 1.8 0l8.9 9.1c.5.5.5 1.3 0 1.8-.3.3-.6.4-.9.4z\"/><path d=\"M11.1 17.3c-.3 0-.6-.1-.9-.4-.5-.5-.5-1.3 0-1.8L19.1 6c.5-.5 1.3-.5 1.8 0s.5 1.3 0 1.8L12 17c-.2.2-.6.3-.9.3z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 30.8 23"
    }
  };
});