define("ember-svg-jar/inlined/icon-hash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Hash</title><path d=\"M10.379.756L9.363 14.078M5.637.5L4.621 13.823M14.5 5.046l-13.323.083m12.646 4.32L.5 9.532\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 15 15"
    }
  };
});