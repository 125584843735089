define("ember-svg-jar/inlined/lang-php", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g opacity=\".8\" fill=\"#5f82ba\"><path d=\"M45.7 22.4h-2.3c-.2 0-.2 0-.3.2-.1.7-.2 1.5-.4 2.2-.1.8-.3 1.5-.4 2.3 0 .2 0 .3.2.3H44c.3 0 .6 0 .8-.1 1-.2 1.8-.7 2.1-1.7.2-.5.2-1 .3-1.5 0-.4-.1-.8-.4-1.1-.1-.4-.6-.5-1.1-.6zM22.5 22.4h-2.3c-.2 0-.2 0-.2.2v.3c-.2 1.4-.5 2.9-.7 4.3 0 .2 0 .3.2.3H21c.3 0 .6 0 .8-.1 1.2-.2 1.9-.9 2.2-2 .1-.4.2-.8.2-1.3 0-.4-.1-.8-.4-1.1-.4-.4-.8-.5-1.3-.6z\"/><path d=\"M32.8 3.2c-12.5 0-22.7 10.2-22.7 22.7s10.2 22.7 22.7 22.7 22.7-10.2 22.7-22.7S45.3 3.2 32.8 3.2zM22.5 29.4H18.9c-.1 0-.2 0-.2.2-.2.9-.4 1.9-.5 2.8-.1.3 0 .3-.3.3h-2.2c-.2 0-.2 0-.1-.2.3-1.3.5-2.7.8-4 .5-2.6 1-5.1 1.5-7.7 0-.1.1-.2.1-.3 0-.1.1-.2.2-.2H23.5c.7 0 1.3.1 1.9.4 1 .4 1.6 1.2 1.7 2.3.2 1.5-.1 3-1.1 4.2-1 1.3-2.1 1.9-3.5 2.2zm14.6-.1c0 .1-.1.1-.1.1h-2.5c-.1 0-.1 0-.1-.1L35 26c.2-.8.3-1.6.5-2.4 0-.2.1-.4 0-.5-.1-.3-.3-.5-.6-.6-.2-.1-.4-.1-.6-.1H32c-.2 0-.2 0-.3.2-.4 2.2-.9 4.4-1.3 6.6 0 .2 0 .2-.2.2h-2.3c-.2 0-.2 0-.1-.2.4-1.8.7-3.7 1.1-5.5.4-2.2.9-4.3 1.3-6.5 0-.1.1-.1.2-.1h2.3c.1 0 .1 0 .1.1-.2 1-.4 1.9-.5 2.9 0 .2 0 .3.2.3h2.7c.8 0 1.5.2 2.2.6.6.4.9 1 .9 1.8 0 .5-.2 1.1-.3 1.6l-.6 3.6c-.1.4-.2.9-.3 1.3zm8.6.1c-.3.1-.6 0-1 0h-2.6-.1c-.2 0-.2 0-.3.2-.1.7-.3 1.3-.4 2-.1.3-.1.6-.2.9 0 .1-.1.1-.2.1h-2.4c-.1 0-.1 0-.1-.1.4-1.8.7-3.7 1.1-5.5.4-2.2.9-4.3 1.3-6.5 0-.1.1-.2.2-.2h5.4c.7 0 1.3.1 2 .4.9.4 1.5 1.2 1.6 2.2 0 .3.1.6.1.8 0 1.3-.3 2.5-1.1 3.5-.8 1.3-1.9 1.9-3.3 2.2z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 61.2 50.4"
    }
  };
});