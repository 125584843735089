define("ember-svg-jar/inlined/icon-assembla", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M39.7 1c-1.5.5-10 5.1-18.9 10.2C-1 23.8 0 22.1 0 49.9c0 23 .3 24.3 5.7 29.2 1.5 1.4 9.6 6.5 17.9 11.2C38.2 98.7 38.9 99 45 99c6.1 0 6.8-.3 21.4-8.7 8.3-4.7 16.4-9.8 17.9-11.2 5.4-4.9 5.7-6.2 5.7-29.2 0-27.8.9-26.1-20.8-38.7C49.8-.1 46.8-1.1 39.7 1zm16.7 42.9c6.4 12.8 9.7 20.4 9.3 21.5-1.1 2.8-2.5 1-8.2-10.5-3.1-6-5.7-10.9-6-10.9-.3 0-2.8 4.6-5.6 10.2-6.2 12.5-6.7 13.2-8.5 11.7-1.1-.9-.1-3.6 5.2-14.1l6.6-13.1-1.8-3.9c-.9-2.1-2-3.8-2.3-3.8-.4 0-4.5 7.7-9.1 17.1-9 18-10.5 20.3-11.6 17.4C23.4 63.1 42.9 24 45 24c1 0 5 6.9 11.4 19.9z\"/>",
    "attrs": {
      "version": "1.0",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "120",
      "height": "132",
      "viewBox": "0 0 90 99"
    }
  };
});