define("ember-svg-jar/inlined/lang-node", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g opacity=\".8\" fill=\"#91c53e\"><path d=\"M40.2 31.1c-.4.3-.9.4-1.4.5-1.6.3-3.2.3-4.7.1-2-.3-3.2-1-3.5-3.1 0-.3-.2-.5-.6-.5h-1.9c-.4 0-.6.2-.6.6 0 1.2.3 2.3 1.1 3.3 1 1.3 2.3 1.9 3.8 2.3 1.8.4 3.7.5 5.6.3 1.3-.1 2.6-.4 3.8-1 1.6-.8 2.5-2.1 2.7-3.9.2-1.6-.1-3.1-1.5-4.2-.8-.6-1.7-.9-2.7-1.1-1.7-.4-3.5-.6-5.2-.9-1-.2-1.9-.3-2.8-.6-.8-.3-1.1-.8-1-1.7.1-.6.4-1.1 1-1.4.4-.2.8-.3 1.2-.4 1.4-.3 2.8-.2 4.3 0s2.6 1.1 2.9 2.6v.2c.1.3.3.5.6.5H43c.7 0 .9-.2.8-.9-.3-2.5-1.7-4.2-4.1-4.9-1.3-.4-2.7-.5-4.2-.5-1.3 0-2.7.2-4.1.7-2.4.9-3.6 2.7-3.4 5.2.1 1.3.7 2.4 1.9 3.1.8.5 1.8.8 2.7 1 1.5.3 2.9.4 4.4.6 1.1.2 2.2.3 3.3.8.6.3.9.7.9 1.4.1.7-.2 1.4-1 1.9z\"/><path d=\"M51.2 25.2v-9.4c0-1.5-.6-2.6-1.9-3.3C43.9 9.3 38.4 6.1 32.9 3c-1.2-.7-2.4-.7-3.6 0l-1.5.9-15 8.7c-1.2.7-1.8 1.7-1.8 3v19.3c0 1.3.6 2.3 1.8 3l4.2 2.4c1.5.8 3.1 1.3 4.8 1.1 1.9-.3 3.2-1.2 3.8-3 .2-.7.3-1.5.3-2.2V17.8v-.4c0-.3-.2-.5-.5-.5h-2.1c-.3 0-.4.2-.5.5v19.1c-.1 1.1-.9 2-2.1 2.1-.7 0-1.4-.2-2.1-.5-1.3-.8-2.6-1.6-4-2.3-.6-.3-.7-.7-.7-1.3V16.3c0-.6.2-1 .7-1.2 5.2-3 10.5-6 15.7-9.1.5-.3 1-.3 1.5 0 5.3 3.1 10.5 6.1 15.8 9.1.5.3.7.6.7 1.1v18.4c0 .5-.2.9-.6 1.1-5.4 3.1-10.7 6.2-16 9.3-.4.2-.7.2-1.1 0-1.2-.8-2.5-1.5-3.8-2.2-.2-.1-.4-.3-.7-.1-.8.5-1.6.8-2.5 1.1-.3.1-.3.3 0 .5.1.1.2.1.3.2 1.8 1 3.5 2.1 5.3 3.1 1.3.8 2.6.8 4 0l16.2-9.3c1.3-.8 2-1.9 2-3.4-.2-3.5-.2-6.6-.2-9.7z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 61.2 50.4"
    }
  };
});