define("ember-svg-jar/inlined/icon-seemore", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path class=\"icon-seemore_svg__st0\" d=\"M14.5 7.419s-3.134 3.919-7 3.919-7-3.919-7-3.919S3.634 3.5 7.5 3.5s7 3.919 7 3.919z\"/><ellipse class=\"icon-seemore_svg__st0\" cx=\"7.5\" cy=\"7.419\" rx=\"2.022\" ry=\"2.335\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 15 15"
    }
  };
});