define("ember-svg-jar/inlined/icon-private", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.246 7.554a1.29 1.29 0 00-.633 2.413v2.082h.001a.633.633 0 001.266 0h.001V9.967a1.288 1.288 0 00-.635-2.413z\" fill=\"none\" stroke=\"#9d9d9d\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"/><path d=\"M11.583 5.847h-.612l.001-1.698C10.972 2.137 9.335.5 7.323.5S3.674 2.137 3.674 4.149l.001 1.698h-.612c-.777 0-1.413.636-1.413 1.413v5.828c0 .777.636 1.413 1.413 1.413h8.521c.777 0 1.413-.636 1.413-1.413V7.26a1.419 1.419 0 00-1.414-1.413zM5.841 4.03c0-.817.665-1.482 1.482-1.482s1.482.665 1.482 1.482v1.817H5.842L5.841 4.03z\" fill=\"none\" stroke=\"#9d9d9d\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 15 15"
    }
  };
});