define("ember-svg-jar/inlined/gitlab-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M26.799 20.482l-.926-2.845a.425.425 0 00-.023-.077l-1.857-5.719a.74.74 0 00-1.403.004l-1.77 5.449h-5.59l-1.773-5.45a.726.726 0 00-.697-.508h-.004a.74.74 0 00-.703.508l-1.856 5.726-.006.014-.943 2.896c-.142.437.012.913.384 1.183l8.145 5.918a.42.42 0 00.493-.002l.001-.001 8.145-5.915c.371-.268.525-.744.383-1.181zm-12.174-2.351l2.271 6.987-5.45-6.987h3.179zm4.529 6.989l2.177-6.699.094-.29h3.182l-4.929 6.317-.524.672zm4.139-12.738l1.596 4.913h-3.191l1.595-4.913zm-2.743 5.746l-1.582 4.868-.942 2.899-2.524-7.766h5.048zm-7.794-5.746l1.599 4.913h-3.191l1.592-4.913zm-2.633 8.609a.224.224 0 01-.081-.251l.7-2.15 5.134 6.582-5.753-4.181zm15.803 0l-5.753 4.178.02-.025 5.115-6.554.7 2.15a.226.226 0 01-.082.251z\" fill=\"#ff5050\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "9.196 11.336 17.655 16.325"
    }
  };
});