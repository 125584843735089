define("ember-svg-jar/inlined/icon-scales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.17 10.79c.04 1.5 1.26 2.71 2.77 2.71s2.73-1.21 2.77-2.71l-3.17-6.08-2.37 6.08zm0 0h5.55M.63 8.15c.04 1.5 1.26 2.71 2.77 2.71s2.73-1.21 2.77-2.71L3 2.06.63 8.15zm0 0h5.54M3 2.06l11.54 2.65M9.19.6v15.21m3.28.1L6 15.88\"/>",
    "attrs": {
      "viewBox": "0 0 18.3 16.5"
    }
  };
});