define("ember-svg-jar/inlined/green-gradient-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<style/><linearGradient id=\"green-gradient-circle_svg__Gradient_Circle__x2014__Large__x2014__Green_1_\" gradientUnits=\"userSpaceOnUse\" x1=\"-404.178\" y1=\"3893.577\" x2=\"-580.45\" y2=\"4103.651\" gradientTransform=\"rotate(180 -177.6 2067.863)\"><stop offset=\"0\" stop-color=\"#fff\" stop-opacity=\"0\"/><stop offset=\".089\" stop-color=\"#e4f7ec\" stop-opacity=\".089\"/><stop offset=\".341\" stop-color=\"#9be0ba\" stop-opacity=\".341\"/><stop offset=\".567\" stop-color=\"#61cf93\" stop-opacity=\".567\"/><stop offset=\".759\" stop-color=\"#38c276\" stop-opacity=\".759\"/><stop offset=\".909\" stop-color=\"#1eba64\" stop-opacity=\".909\"/><stop offset=\"1\" stop-color=\"#15b75e\"/></linearGradient><circle id=\"green-gradient-circle_svg__Gradient_Circle__x2014__Large__x2014__Green\" cx=\"137.113\" cy=\"137.113\" r=\"137.113\" fill=\"url(#green-gradient-circle_svg__Gradient_Circle__x2014__Large__x2014__Green_1_)\"/>",
    "attrs": {
      "version": "1.1",
      "id": "green-gradient-circle_svg__Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 274.226 274.226",
      "xml:space": "preserve"
    }
  };
});