define("ember-svg-jar/inlined/icon-api", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>API event</title><path d=\"M13.676 5.327h-1.153V3.156c0-.494-.329-.906-.824-.906H9.147c-.412 0-.824.412-.824.906v2.171H6.429V3.156c0-.494-.329-.906-.824-.906h-2.47c-.412 0-.824.412-.824.906v2.171h-.987c-.412 0-.824.412-.824.906v5.076c0 .494.329.906.824.906h12.353c.412 0 .824-.412.824-.906V6.233c-.001-.494-.33-.906-.825-.906z\"/>",
    "attrs": {
      "viewBox": "0 0 15 15"
    }
  };
});