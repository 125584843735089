define("ember-svg-jar/inlined/icon-arch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" stroke=\"#9da2a7\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"><path d=\"M6.29 5.8h9.4v9.4h-9.4zM14.2 18.05v-.96M7.73 18.05v-.96M10.99 18.05v-.96M14.2 4.02V2.94M7.73 4.02V2.94M10.99 4.02V2.94M18.52 7.26h-.96M18.52 13.73h-.96M18.52 10.48h-.96M4.5 7.26H3.41M4.5 13.73H3.41M4.5 10.48H3.41\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "3 1 17 17"
    }
  };
});