define("ember-svg-jar/inlined/job-name-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M21.1 14.9c0 1.1-.9 2-2 2H7.9c-1.1 0-2-.9-2-2V8.1c0-1.1.9-2 2-2h11.2c1.1 0 2 .9 2 2v6.8z\" fill=\"none\" stroke=\"#9da2a7\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"/><g fill=\"none\" stroke=\"#9da2a7\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"><path d=\"M13.3 8.7h-5M18.3 11.5h-10M18.3 14.3h-10\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "4 2 18 18"
    }
  };
});