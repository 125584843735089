define("ember-svg-jar/inlined/icon-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.709 8.91l-1.13-.659c.037-.247.076-.494.076-.751a5.03 5.03 0 00-.083-.82l1.117-.672c.48-.289.636-.917.348-1.397l-.863-1.436a1.021 1.021 0 00-1.397-.348l-1.121.674s-.76-.515-1.193-.686V1.518A1.02 1.02 0 008.445.5H6.769a1.02 1.02 0 00-1.018 1.018v1.296c-.454.18-.866.432-1.245.729l-1.132-.66a1.02 1.02 0 00-1.392.367l-.844 1.447a1.022 1.022 0 00.366 1.393l1.13.659c-.037.247-.076.494-.076.751 0 .281.039.552.083.82l-1.117.672c-.48.289-.636.917-.348 1.397l.864 1.436c.289.48.917.636 1.397.348l1.121-.674c.365.279.76.515 1.193.686v1.296c0 .56.458 1.018 1.018 1.018h1.676a1.02 1.02 0 001.018-1.018v-1.296s.866-.432 1.245-.729l1.132.66a1.02 1.02 0 001.392-.367l.844-1.448a1.02 1.02 0 00-.367-1.391zm-6.102.669a2.08 2.08 0 110-4.159 2.08 2.08 0 010 4.159z\"/>",
    "attrs": {
      "viewBox": "0 0 15 15"
    }
  };
});