define("ember-svg-jar/inlined/lang-scala", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#dc342e\" opacity=\".8\"><path d=\"M22.4 57.3v-7.8c0-1 .4-1.5 1.4-1.6 3.8-.5 7.6-1 11.4-1.6 3-.5 6.1-1 9-2 .9-.3 1.8-.8 2.6-1.7V51c0 .9-.3 1.4-1.1 1.8-1.6 1-3.5 1.4-5.3 1.8-4.7 1-9.4 1.6-14.1 2.2-1 .1-2 .1-3 .5h-.9zM46.8 18.4v9c0 .6-.4.9-.9 1.2-1.3.8-2.7 1.2-4.2 1.6-4.9 1.2-10 1.8-15 2.5-1.1.2-2.2.3-3.3.5-.6.1-.9-.1-.9-.7V25c0-1 .8-1 1.4-1.1 2.5-.4 5-.7 7.5-1 4.3-.6 8.5-1.1 12.6-2.5 1-.5 1.9-.9 2.8-2zM46.8 30.5v8.9c0 .7-.5 1-1 1.3-1.4.9-3 1.3-4.6 1.6-5 1.2-10.2 1.7-15.3 2.5-.9.1-1.7.3-2.6.4-.5.1-.8 0-.8-.6v-7.7c0-.8.6-.9 1.2-1 3.3-.5 6.5-.9 9.8-1.4 3.2-.5 6.4-1 9.5-1.9 1.3-.4 2.6-.9 3.8-2.1z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 61.2 61.9"
    }
  };
});