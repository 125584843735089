define("ember-svg-jar/inlined/maintenance-grass-shade-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path opacity=\".1\" fill=\"#49623D\" d=\"M181.484 0H0l11.022 10.288h157.99l7.348 6.613H87.445l5.879 8.083h118.303z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "211.627",
      "height": "24.984",
      "viewBox": "0 0 211.627 24.984"
    }
  };
});