define("ember-svg-jar/inlined/icon-arrow-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18 8.66l-7.3 7.3a.99.99 0 01-1.39 0L2 8.66\" fill=\"none\" stroke=\"#44aaae\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 20 21.29"
    }
  };
});