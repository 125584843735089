define("ember-composable-helpers/utils/get-index", ["exports", "@ember/array", "ember-composable-helpers/utils/is-equal"], function (_exports, _array, _isEqual) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getIndex;
  function getIndex(array, currentValue, useDeepEqual) {
    let needle = currentValue;
    if (useDeepEqual) {
      needle = (0, _array.A)(array).find(object => {
        return (0, _isEqual.default)(object, currentValue, useDeepEqual);
      });
    }
    let index = (0, _array.A)(array).indexOf(needle);
    return index >= 0 ? index : null;
  }
});