define("ember-svg-jar/inlined/icon-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" stroke=\"#9ca1a6\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"><path d=\"M5.3 2.7h9.2M.5 2.7h.6M13.1 7.9h1.4M.5 7.9h8.4M.5 13.2h1.6M6.3 13.2h8.2\"/><circle cx=\"3.2\" cy=\"2.7\" r=\"2.1\"/><circle cx=\"11\" cy=\"7.9\" r=\"2.1\"/><circle cx=\"4.2\" cy=\"13.2\" r=\"2.1\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 15 15.9"
    }
  };
});