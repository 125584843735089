define("ember-svg-jar/inlined/favicon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M.4 8H.2c-.1-.1-.2-.2-.2-.3V5.1c0-.1.1-.2.2-.2C1.6 4.1 4.6 4 4.7 4c.2 0 .3.1.3.3s-.1.3-.2.3c0 0-2.8.1-4.1.7v2c.9-.2 3.6-.6 7-.6h.1c3.4 0 5.7.4 6.6.6v-2c-1.4-.6-4.1-.7-4.1-.7-.2 0-.3-.2-.3-.3s.1-.3.3-.3c.1 0 3.1.1 4.6.9 0 0 .1.1.1.2v2.6c0 .1 0 .2-.1.2-.1.1-.2.2-.3.1 0 0-2.6-.6-6.8-.6h-.1C3.4 7.4.4 8 .4 8z\"/><path d=\"M13.7 5.1c-.1 0-.3-.1-.3-.2-.5-1.7-1.6-3-3.3-3.7C8.7.6 7.5.6 7.5.6S6.3.6 5 1.1c-1.8.8-2.9 2-3.4 3.7 0 .2-.2.3-.3.2-.2 0-.3-.2-.3-.3.5-1.9 1.8-3.3 3.7-4.1C6.2 0 7.4 0 7.5 0c.1 0 1.3 0 2.7.6 1.9.8 3.2 2.2 3.7 4.1.1.1 0 .3-.2.4zM12.2 11.4l-3-.2c-.1 0-.2-.1-.2-.2L7.9 9.1h-1L5.7 11c-.1.1-.1.2-.3.2l-3 .2c-.1 0-.2 0-.3-.1l-1-1.2S1 10 1 9.9V7.6c0-.2.1-.3.3-.3.2 0 .3.1.3.3l.2 2.1.8 1 2.6-.2 1.1-2c.1-.1.2-.2.3-.2H8c.1 0 .2.1.3.2l1.1 2 2.6.2.8-1 .2-2.1c0-.2.2-.3.3-.3.2 0 .3.2.3.3l-.2 2.2c0 .1 0 .1-.1.2l-1 1.2c.1.1 0 .2-.1.2z\"/><path d=\"M3.4 15.8c-.1 0-.2 0-.2-.1L.5 12.6c-.1-.1-.1-.3 0-.4.1-.1.2-.2.4-.1l2 .9 2.1-.5 1-1c.1-.1.2-.1.3-.1l1.3.6 1.1-.6c.1-.1.2 0 .3 0l1.3.9 1.7.1 1.6-.9c.1-.1.3-.1.4 0 .1.1.1.2.1.4l-1 2.2c0 .1-.1.1-.2.2l-3.5 1.2h-.3l-1.4-.9-1.3 1s-.1.1-.2.1l-2.8.1zm-1.5-2.6l1.6 1.9 2.5-.2 1.4-1.1c.1-.1.3-.1.4 0l1.5 1 3.2-1.1.5-1.2-.7.5h-2.1c-.1 0-.1 0-.2-.1L8.8 12l-1.1.6h-.3l-1.3-.5-1 .8-.1.1-2.2.6h-.2l-.7-.4z\"/><path d=\"M2.9 13.6c-.1 0-.2-.1-.3-.2-.3-.8-.4-2.2-.4-2.3 0-.2.1-.3.3-.3.2 0 .3.1.3.3 0 0 .1 1.4.3 2.1.1.2 0 .3-.2.4.1 0 .1 0 0 0zM11.9 13.1c-.1 0-.1 0-.2-.1s-.2-.3-.1-.4c.4-.6.5-1.8.5-1.8 0-.2.2-.3.3-.3.2 0 .3.2.3.3 0 .1-.1 1.4-.6 2.1 0 .1-.1.2-.2.2zM7.5 16.6c-1.9 0-2.8-1.1-2.8-1.1-.1-.1-.1-.3.1-.4.1-.1.3-.1.4 0 0 0 .7.9 2.3.9 1.6 0 2.9-1.4 2.9-1.4.1-.1.3-.1.4 0 .1.1.1.3 0 .4 0 0-1.4 1.6-3.3 1.6zM8.1 6.3H6.7c-.1 0-.3-.1-.3-.3V3.5h-.7c-.2 0-.3-.1-.3-.3 0-1.3 0-1.3.1-1.4.1-.1.1-.1.2-.1h3.8c.2 0 .3.1.3.3v1.3c0 .2-.1.3-.3.3h-1V6c-.1.2-.3.3-.4.3zm-1-.6h.7V3.2c0-.2.1-.3.3-.3h1v-.6H5.9v.6h.8c.2 0 .3.1.3.3v2.5z\"/><circle cx=\"10.7\" cy=\"9\" r=\".7\"/><circle cx=\"4\" cy=\"9\" r=\".7\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 15 16.6"
    }
  };
});