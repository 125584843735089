define("ember-svg-jar/inlined/icon-trash-disabled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.273 8.124l-.258-3.275h3.441m5.768.61l-.712 9.041H3.775M2 2.913h6.315M9.76 1.261A.763.763 0 008.999.5H6.288a.763.763 0 00-.761.761v1.652m.944 8.684l.048.715m2.504-3.463l-.235 3.463M13.045.957L2.053 12.769\" fill=\"none\" stroke=\"#9d9d9d\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 15 15"
    }
  };
});