define("ember-svg-jar/inlined/engineyard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#e92c35\"><path d=\"M11 22.3h9.7v3.1h-6.2v3.9h6.1v3h-6.1v4.2h6.2v3.1H11zM22.9 26.8h2.9v1.7c.6-1 1.7-2.1 4-2.1 4.2 0 5 3.2 5 5.5v7.7h-3.1v-7c0-1.9-.6-3.4-2.8-3.4-2.2 0-2.9 1.5-2.9 3.3v7.1h-3.1V26.8zM50.3 38.1c0 2.1-.4 3.5-1.2 4.5-1.1 1.5-3.2 2.6-5.9 2.6-4 0-5.9-2.4-6.4-4.4h3.5c.3.6.8 1 1.3 1.3.6.3 1.3.4 1.7.4 1.8 0 3.8-1.3 3.8-3.9v-1.1c-.6 1.1-2 2.3-4.2 2.3-3.7 0-6.5-2.8-6.5-6.7 0-4.2 3-6.8 6.5-6.8 1.8 0 3.4.8 4.2 2.1v-1.7h3.1v11.4zm-10.7-4.9c0 2.2 1.6 3.9 3.8 3.9 1.1 0 2.1-.4 2.7-1.1.7-.7 1.1-1.6 1.1-2.7 0-1-.3-2-.9-2.8-.6-.8-1.6-1.3-2.9-1.3-2.1 0-3.8 1.6-3.8 4zM52.8 26.8h3.1v12.8h-3.1zM58.4 26.8h2.9v1.7c.6-1 1.7-2.1 4-2.1 4.2 0 5 3.2 5 5.5v7.7h-3.1v-7c0-1.9-.6-3.4-2.8-3.4-2.2 0-2.9 1.5-2.9 3.3v7.1h-3.1V26.8zM74.5 34.2c.1 2.1 2 3.2 3.7 3.2 1.5 0 2.5-.7 3.1-1.7h3.2c-.6 1.4-1.5 2.5-2.6 3.2-1.1.7-2.3 1.1-3.7 1.1-3.9 0-6.8-3.2-6.8-6.8 0-3.6 2.8-6.8 6.7-6.8 1.9 0 3.6.7 4.8 2 1.6 1.6 2.1 3.6 1.9 5.9H74.5zm7.3-2.3c-.1-1.2-1.4-2.9-3.6-2.9-2.3 0-3.5 1.6-3.6 2.9h7.2zM96.4 33.4l-5.3-11.1h3.7l3.3 7.8 3.3-7.8h3.7l-5.2 11.1v6.2h-3.5zM117.3 39.6h-3.1v-1.8c-.8 1.5-2.5 2.2-4.3 2.2-4.1 0-6.5-3.2-6.5-6.8 0-4 2.9-6.8 6.5-6.8 2.3 0 3.8 1.2 4.3 2.3v-1.9h3.1v12.8zm-10.8-6.4c0 1.6 1.1 3.9 3.9 3.9 1.7 0 2.8-.9 3.4-2.1.3-.6.4-1.2.5-1.8 0-.6-.1-1.2-.4-1.8-.5-1.2-1.7-2.3-3.6-2.3-2.4.1-3.8 2.1-3.8 4.1zM119.9 26.8h2.9v1.7c.6-1.1 1.6-2.1 3.5-2.1v3.1h-.1c-2.1 0-3.2 1-3.2 3.2v6.9h-3.1V26.8zM140.2 39.6h-2.9v-1.8c-.6 1-1.8 2.2-4.4 2.2-3.9 0-6.5-3.1-6.5-6.8 0-4.2 3-6.8 6.4-6.8 2.5 0 3.8 1.3 4.3 2.1v-6.1h3.1v17.2zm-10.7-6.4c0 2.2 1.6 3.9 3.9 3.9 1.1 0 2.1-.4 2.8-1.2.7-.7 1.1-1.7 1.1-2.8 0-1.1-.4-2.1-1.1-2.8-.7-.7-1.7-1.1-2.8-1.1-2.5 0-3.9 1.9-3.9 4zM49.7 22.6h2.8l2.4 2.3v-4.4c0-1-.8-1.7-1.7-1.7-.4 0-.8.1-1.1.4-.4-.9-1.3-1.6-2.3-1.6-1.4 0-2.5 1.1-2.5 2.5-.2 1.4 1 2.5 2.4 2.5M142 22.7h-.4v-.4h1.3v.4h-.4v1.6h-.5v-1.6zm1.1-.4h.6l.5 1.4.5-1.4h.6v2h-.4v-1.5l-.5 1.5h-.3l-.5-1.4v1.4h-.4v-2z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 147.3 56.2"
    }
  };
});