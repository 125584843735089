define("ember-metrics/metrics-adapters/base", ["exports", "@ember/object", "@ember/debug", "@ember/object/internals", "@ember/utils", "@ember/application"], function (_exports, _object, _debug, _internals, _utils, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function makeToString(ret) {
    return () => ret;
  }
  class BaseAdapter extends _object.default {
    constructor(config, owner) {
      super(...arguments);
      _defineProperty(this, "metrics", null);
      _defineProperty(this, "config", null);
      (0, _application.setOwner)(this, owner);
      this.config = config;
      this.init();
    }

    // eslint-disable-next-line ember/classic-decorator-hooks
    init() {
      (false && !(false) && (0, _debug.assert)(`[ember-metrics] ${this.toString()} must implement the init hook!`));
    }
    willDestroy() {
      (false && !(false) && (0, _debug.assert)(`[ember-metrics] ${this.toString()} must implement the willDestroy hook!`));
    }
    get() {
      (false && !(false) && (0, _debug.deprecate)('Metrics Adapters must not use EmberObject methods as they will be implemented as native classes in the next major release', false, {
        id: 'ember-metrics-issue-287',
        for: 'ember-metrics',
        url: 'https://github.com/adopted-ember-addons/ember-metrics/issues/287',
        since: '1.4.0',
        until: '2.0.0'
      }));
      super.get(...arguments);
    }
    set() {
      (false && !(false) && (0, _debug.deprecate)('Metrics Adapters must not use EmberObject methods as they will be implemented as native classes in the next major release', false, {
        id: 'ember-metrics.issue-287',
        for: 'ember-metrics',
        url: 'https://github.com/adopted-ember-addons/ember-metrics/issues/287',
        since: '1.4.0',
        until: '2.0.0'
      }));
      super.set(...arguments);
    }
    toString() {
      const hasToStringExtension = (0, _utils.typeOf)(this.toStringExtension) === 'function';
      const extension = hasToStringExtension ? ':' + this.toStringExtension() : '';
      const ret = `ember-metrics@metrics-adapter:${extension}:${(0, _internals.guidFor)(this)}`;
      this.toString = makeToString(ret);
      return ret;
    }
    identify() {}
    trackEvent() {}
    trackPage() {}
    alias() {}
  }
  _exports.default = BaseAdapter;
  _defineProperty(BaseAdapter, "supportsFastBoot", false);
});