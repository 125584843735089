define("ember-svg-jar/inlined/icon-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" stroke=\"#9d9d9d\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"><path d=\"M14.382 12.469c.265.431.068.784-.438.784H1.056c-.506 0-.703-.353-.438-.784l6.4-10.399c.265-.431.7-.431.965 0l6.399 10.399zM7.5 5.275v3.39M7.5 10.446v.813\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 15 15"
    }
  };
});