define('deep-freeze', ['exports'], function (exports) { 'use strict';

  var deepFreeze = function deepFreeze (o) {
    Object.freeze(o);

    Object.getOwnPropertyNames(o).forEach(function (prop) {
      if (o.hasOwnProperty(prop)
      && o[prop] !== null
      && (typeof o[prop] === "object" || typeof o[prop] === "function")
      && !Object.isFrozen(o[prop])) {
        deepFreeze(o[prop]);
      }
    });
    
    return o;
  };

  exports.default = deepFreeze;

  Object.defineProperty(exports, '__esModule', { value: true });

});
