define("ember-svg-jar/inlined/Gh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M91.5 20.7c-7.6 0-13.8 6.4-13.8 14.2 0 6.3 4 11.6 9.5 13.5.7.1.9-.3.9-.7v-2.4c-3.8.9-4.7-1.9-4.7-1.9-.6-1.6-1.5-2.1-1.5-2.1-1.3-.9.1-.9.1-.9 1.4.1 2.1 1.5 2.1 1.5 1.2 2.2 3.2 1.5 4 1.2.1-.9.5-1.5.9-1.9-3.1-.4-6.3-1.6-6.3-7 0-1.6.5-2.8 1.4-3.8-.1-.4-.6-1.8.1-3.8 0 0 1.2-.4 3.8 1.5 1.1-.3 2.3-.5 3.5-.5 1.2 0 2.4.2 3.5.5 2.6-1.8 3.8-1.5 3.8-1.5.8 2 .3 3.4.1 3.8.9 1 1.4 2.3 1.4 3.8 0 5.4-3.2 6.7-6.3 7 .5.4.9 1.3.9 2.6v3.9c0 .4.2.8.9.7 5.5-1.9 9.5-7.2 9.5-13.5 0-7.9-6.2-14.2-13.8-14.2z\" opacity=\".4\" fill=\"#010101\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 147.3 56.2"
    }
  };
});