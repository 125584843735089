define("ember-svg-jar/inlined/icon-perforce", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<style/><path d=\"M9.7 22.5c-.5.6-.9 1.2-1.4 1.9-2.1-1.4-3.6-3.2-4.5-5.5-2.7-6.7.7-15.6 9.5-17.5 2.8-.6 5.5-.2 8 1.1.3.2.5.2.8-.1.4-.4.9-.8 1.3-1.3 1.8 1.2 3 2.8 3.8 4.7 1.5 3.7 1.3 7.3-.1 10.9-1.6 4-4.6 6.4-8.8 7.3-2.8.6-5.5.1-8-1.2l-.6-.3zm14.1-5.3c.1-.2.2-.3.2-.5 1.8-3.5 1.1-7.8-1.6-10.5s-6.1-3.5-9.8-2.6c-2.4.4-4.3 1.9-5.9 3.7.1.1.1.2.2.2.5.3.5.6.2 1.2-1.4 3-1.2 6 .6 8.8 2.6 4.2 8.8 5.8 13.3 3.4 1.4-.8 2.6-1.7 3.6-3-.3-.3-.5-.5-.8-.7z\" fill=\"#9d9d9d\"/>",
    "attrs": {
      "version": "1.1",
      "id": "icon-perforce_svg__Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 31.2 25.5",
      "xml:space": "preserve"
    }
  };
});