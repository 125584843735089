define("ember-svg-jar/inlined/icon-invoice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.208 0H.4a.4.4 0 00-.4.4v15.2a.4.4 0 00.4.4h11.8a.4.4 0 00.4-.4V4.4z\" fill=\"#e0dede\"/><path d=\"M12.608 4.4h-4a.4.4 0 01-.4-.4V0z\" fill=\"#aaa9a9\"/><g fill=\"none\" stroke=\"#666766\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M7.341 6.813a1.176 1.176 0 00-.961-.4.959.959 0 00-1.051 1.005c0 .582.317.87 1.119 1.2s1.057.757 1.057 1.277a1.1 1.1 0 01-1.153 1.136 1.71 1.71 0 01-1.249-.5M6.38 6.412V5.244M6.38 12.197v-1.168\"/></g>",
    "attrs": {
      "viewBox": "0 0 12.608 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});