define("ember-svg-jar/inlined/icon-sign-out", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" stroke=\"#686b6e\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"><path d=\"M10.99 10.78l2.45-4.87c0-.01.01-.02.01-.03M14.99 6.5c0 .01-.01.02-.01.03L13.64 9.2M11.21 4.07a1.039 1.039 0 00-.78-.06l.27-.58c.25-.52.05-1.16-.44-1.42a.96.96 0 00-.78-.05c.2-.51 0-1.09-.47-1.34A.987.987 0 008.55.5c-.39 0-.75.24-.93.6-.1-.19-.25-.36-.45-.46-.14-.07-.3-.11-.46-.11-.39 0-.76.24-.94.61L3.01 6.5l-.48-1.97c0-.01-.01-.03-.01-.04-.11-.49-.51-.83-.98-.83-.06 0-.12.01-.18.02-.44.08-.8.47-.86.94 0 .05-.12 5.31 1.61 7.17.37.4.77.71 1.22.95.01 0 .01.01.02.01.64.35 1.33.53 2.02.53h.14c.5-.02.98-.13 1.44-.33.4-.18.97-.5 1.41-1.02.2-.24.33-.5.45-.75l.07-.14c.11-.21.31-.64.31-.64l2.44-4.86c.27-.57.07-1.21-.42-1.47zM5.83 5.15L7.79.84M9.48 1.96L7.61 5.91M10.43 4.01L9.01 6.89\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 15.49 13.78"
    }
  };
});