define("ember-svg-jar/inlined/close-chat-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"28\" cy=\"28\" r=\"28\" fill=\"#3EAAAF\"/><path d=\"M37.953 39.65a1.206 1.206 0 001.698 0 1.206 1.206 0 000-1.697L29.698 28l9.953-9.953a1.206 1.206 0 000-1.698 1.206 1.206 0 00-1.698 0L28 26.302l-9.953-9.953a1.206 1.206 0 00-1.698 0 1.206 1.206 0 000 1.698L26.302 28l-9.953 9.953a1.206 1.206 0 000 1.698 1.206 1.206 0 001.698 0L28 29.698l9.953 9.953z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "56",
      "height": "56",
      "viewBox": "0 0 56 56",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});