define("ember-svg-jar/inlined/icon-builds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path class=\"icon-builds_svg__st0\" d=\"M11.553 11.153c0 .663.589 1.216 1.289 1.105.442-.074.811-.442.884-.884.111-.553-.221-1.032-.663-1.216-.258-.111-.405-.368-.405-.663v-3.26M.868 5.867C.647 5.867.5 4.117.5 4.117c0-.221.147-.442.332-.479l2.616-.958h3.193l7.491 1.032c.221.036.368.22.368.404 0 0-.184 1.75-.368 1.75L.868 5.867zm5.767 8.602V1.206c0-.221-1.161-.405-1.345-.442L3.816.506c-.184-.037-.369.11-.369.294v13.632h2.211l.977.037zm2.21 0H1.237h7.608z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 15 15"
    }
  };
});