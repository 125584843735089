define("ember-svg-jar/inlined/lang-xcode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#2bace2\"><g opacity=\".8\"><path d=\"M54.9 6.9c-1.2-.2-1.8-.6-2.4-.1-.7.4-1.7.4-2.2-.3-.5-.7-1.1-1.4-2.5-1.9s-8-2.5-14.6.7c0 0-.5.3-.4.5 0 0 .2.1.4 0 .1-.1.4-.1.6-.1l-.7.8c0 .1.1.1.2.1.5-.1 1.7-.4 4-.4 2.7 0 4.6 1.5 4.8 4.5v1.2c-.3 3-1 6.5-2.3 10.6-.4 1.2-.8 2.4-1.3 3.7-2.2 5.9-7.2 17.8-7.1 20.3.1 2.4 3 2.5 3.8 2.4.7-.1 1.4.6 3.8-9.9 2-8.5 2.9-13 3.6-15.7.2-.6.3-1.2.4-1.6.3-1.2 1-3.5 1.8-5.7.8-2.3 1.8-4.4 2.7-5 1.8-1.1 3.2-.2 3.7.5s.9.8 1.7.9c.8.1 1.3.4 1.9-1.9.6-2.3 1.3-3.4.1-3.6z\"/><path d=\"M53.4 41.7l-4.2-27.1c-.1 0-.1.1-.2.1-.2-.1-.6 1.2-1 2.5L51.6 40c0 .1-.1.3-.2.3L41.9 42c-.2.6-.3 1.2-.4 1.7l11.9-2zM13.2 46.4h-.1c-.1 0-.1-.1-.1-.2L8.5 16.5c0-.1.1-.2.2-.3l29.8-4.9v-.2c0-.6-.1-1.1-.3-1.6L6.5 14.8l5.3 33.5 16.4-2.7c.1-.4.2-1 .4-1.7l-15.4 2.5z\"/></g><path d=\"M25.1 19.8s.3-1.1 2-.4c1.5.6 1 1.6 1 1.6l-.7 1.8-3.1-1.1.8-1.9zM18.1 41.5l.4-5.4 3 1.1zM19.6 33.6l4-9.8 3 1.1-3.9 9.8zM14.9 29.3l3.9-.6-1.2 3.1-2.2.4zM28.3 27.2l4.1-.7 2 2.7-7.3 1.2zM45.6 24.5l-1.1.1-.7 3 2.3-.3zM35.6 27.2l-7.5-9.1s-.9-.8.5-1.9c1.3-1.1 1.9-.2 1.9-.2l6.4 7.7-1.3 3.5zM42.1 34.9c.3.2.1.3.3.4 1 .3 1.7.9 2.2 1.8s.6-.1.6-.1c.5-3.5-.7-4.8-1.5-5.5-.4-.3-.5-.6-.8-.6l-.8 4z\" opacity=\".8\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 61.2 50.4"
    }
  };
});