define('url/index', ['exports', 'url/-anchor-url', 'url/-node-url', 'url/-url-search-params'], function (exports, _anchorUrl, _nodeUrl, _urlSearchParams) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.URLSearchParams = undefined;
  exports.needPolyfill = needPolyfill;


  var URL = window && window.URL;
  var URLSearchParams = window && window.URLSearchParams || _urlSearchParams.default;

  const isFastBoot = typeof FastBoot !== 'undefined';
  const hasURL = typeof URL === 'function';
  const testURL = hasURL && new URL('http://example.com');

  function needPolyfill() {
    return !isFastBoot && (!hasURL || !testURL.href || !Object.getOwnPropertyDescriptor(URL.prototype, 'search'));
  }

  if (isFastBoot) {
    URL = _nodeUrl.default;
    exports.URLSearchParams = URLSearchParams = _nodeUrl.URLSearchParams || _urlSearchParams.default;
  } else if (needPolyfill()) {
    URL = _anchorUrl.default;
  } else if (hasURL && !testURL.searchParams) {
    (0, _urlSearchParams.upgradeClass)(URL);
  }

  exports.URLSearchParams = URLSearchParams;
  exports.default = URL;
});