define("ember-svg-jar/inlined/travis-ci-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#9d9d9d\"><path d=\"M5.759 4.603H0V1.635h15.021v2.967H9.295v16.833H5.759V4.603zM14.709 6.57h2.894l.245 2.633h.123c1.064-1.936 2.649-2.995 4.237-2.995.76 0 1.247.09 1.705.301l-.61 3.028c-.518-.152-.914-.242-1.554-.242-1.188 0-2.62.817-3.534 3.059v9.082h-3.504V6.57h-.002zM33.481 11.93c-.032-1.574-.702-2.907-2.713-2.907-1.492 0-2.895.665-4.205 1.452l-1.281-2.331c1.646-1.03 3.748-1.937 6.125-1.937 3.75 0 5.578 2.271 5.578 6.417v8.812H34.12l-.272-1.634h-.094c-1.34 1.12-2.864 1.998-4.631 1.998-2.622 0-4.45-1.758-4.45-4.36 0-3.18 2.682-4.845 8.808-5.51zm-3.262 7.145c1.22 0 2.165-.605 3.263-1.636v-3.33c-4.056.516-5.396 1.574-5.396 3.059-.001 1.331.884 1.907 2.133 1.907zM37.988 6.57h3.566l2.376 7.721c.396 1.453.854 2.967 1.249 4.48h.123c.428-1.515.853-3.027 1.28-4.48l2.376-7.721h3.384l-5.089 14.866h-4.054L37.988 6.57zM53.655 1.999C53.655.817 54.569 0 55.817 0c1.25 0 2.164.817 2.164 1.999 0 1.15-.914 1.968-2.164 1.968-1.248-.001-2.162-.819-2.162-1.968zm.396 4.571h3.505v14.866h-3.505V6.57zM61.21 17.501c1.341 1.026 2.62 1.664 4.144 1.664 1.617 0 2.377-.757 2.377-1.785 0-1.243-1.613-1.789-3.201-2.394-1.979-.728-4.202-1.848-4.202-4.327 0-2.607 2.102-4.452 5.454-4.452 2.071 0 3.75.848 4.967 1.786l-1.614 2.119c-1.036-.756-2.102-1.271-3.291-1.271-1.495 0-2.194.696-2.194 1.634 0 1.151 1.493 1.636 3.106 2.21 2.044.758 4.298 1.728 4.298 4.513 0 2.542-2.042 4.603-5.819 4.603-2.041 0-4.207-.878-5.668-2.061l1.643-2.239zM88.242 1.272c2.436 0 4.418 1.18 5.667 2.512l-1.95 2.181c-1.036-1-2.162-1.635-3.688-1.635-3.263 0-5.607 2.726-5.607 7.176 0 4.512 2.164 7.234 5.483 7.234 1.77 0 3.077-.725 4.207-1.965l1.949 2.148c-1.614 1.846-3.685 2.876-6.216 2.876-5.06 0-9.05-3.636-9.05-10.204.001-6.478 4.084-10.323 9.205-10.323zM96.467 1.635H100v19.801h-3.533V1.635z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "100.266",
      "height": "22",
      "viewBox": "0 0 100.266 22"
    }
  };
});