define("ember-svg-jar/inlined/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#CCC\" d=\"M1 5.013c0-.395.077-.772.229-1.132.153-.36.36-.67.621-.931s.571-.467.931-.62c.36-.153.737-.23 1.132-.23.394 0 .771.077 1.131.229.36.153.67.36.931.621s.467.571.621.931c.153.36.229.737.229 1.132 0 .606-.171 1.157-.513 1.65l1.419 1.419a.507.507 0 01.153.373c0 .143-.052.267-.157.372s-.229.157-.373.157a.493.493 0 01-.373-.157L5.563 7.412a2.833 2.833 0 01-1.65.513 2.88 2.88 0 01-1.132-.229c-.36-.153-.67-.36-.931-.621s-.467-.571-.621-.931A2.88 2.88 0 011 5.013zm1.059 0c0 .51.181.946.544 1.309s.799.544 1.31.544.946-.181 1.309-.544.544-.799.544-1.309-.181-.947-.544-1.31-.799-.544-1.309-.544-.947.181-1.31.544-.544.799-.544 1.31z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "8.333",
      "height": "8.667",
      "viewBox": "0 1 8.333 8.667"
    }
  };
});