define("ember-svg-jar/inlined/push-icon-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#EDEEEE\" d=\"M32.75 65.249C14.83 65.249.251 50.67.251 32.75S14.83.251 32.75.251 65.249 14.83 65.249 32.75 50.67 65.249 32.75 65.249zm0-61.998c-16.266 0-29.499 13.233-29.499 29.499S16.484 62.249 32.75 62.249 62.249 49.016 62.249 32.75 49.016 3.251 32.75 3.251z\"/><path fill=\"#797A7D\" d=\"M37.924 40.609c.807.691 1.526 2.063 1.526 4.162 0 3.006-.028 5.434-.028 6.166 0 .184.041.375.122.545h-11.39c.086-.17.125-.361.125-.545 0-.533-.021-1.944-.032-3.82-6.254 1.359-7.571-3.014-7.571-3.014-1.023-2.6-2.495-3.291-2.495-3.291-2.04-1.394.153-1.365.153-1.365 2.256.16 3.443 2.32 3.443 2.32 2.004 3.432 5.259 2.441 6.541 1.862.203-1.453.784-2.44 1.429-3.003-4.989-.568-10.24-2.496-10.24-11.11 0-2.455.876-4.461 2.315-6.033-.233-.568-1.005-2.854.221-5.945 0 0 1.885-.606 6.178 2.303a21.554 21.554 0 015.63-.757c1.91.008 3.83.259 5.629.757 4.291-2.909 6.172-2.303 6.172-2.303 1.229 3.092.455 5.377.224 5.945 1.438 1.572 2.312 3.578 2.312 6.033-.002 8.638-5.256 10.535-10.264 11.093z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "65.5",
      "height": "65.5",
      "viewBox": "0 0 65.5 65.5"
    }
  };
});