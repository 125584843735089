define("ember-svg-jar/inlined/icon-broadcast", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g class=\"icon-broadcast_svg__tower-path\"><circle class=\"icon-broadcast_svg__path\" cx=\"7.503\" cy=\"6.733\" r=\"1.775\"/><path class=\"icon-broadcast_svg__path\" d=\"M8.303 8.508l5.324 12.698-8.899-6.765\"/><path class=\"icon-broadcast_svg__path\" d=\"M6.828 8.508l-5.3 12.698 8.899-6.765M7.5 8.508v12.165\"/></g><g class=\"icon-broadcast_svg__radio-wave--outer\"><path class=\"icon-broadcast_svg__path\" d=\"M2.454 11.81A6.936 6.936 0 01.5 6.981C.5 4.045 2.312 1.54 4.875.5m7.793 11.168A6.919 6.919 0 0014.5 6.981c0-2.936-1.812-5.441-4.375-6.481\"/></g><g class=\"icon-broadcast_svg__radio-wave--inner\"><path class=\"icon-broadcast_svg__path\" d=\"M3.751 9.161a4.318 4.318 0 01-.628-2.239A4.373 4.373 0 015.858 2.87m5.481 6.116a4.285 4.285 0 00.537-2.064A4.373 4.373 0 009.141 2.87\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 15 21.625"
    }
  };
});