define("ember-svg-jar/inlined/icon-git", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.4 24.2c-.6 0-1.1-.3-1.7-.9-3-3-6-6-9.1-9-1.1-1.1-1.1-2.3 0-3.4C6 9.5 7.5 8.1 9 6.6l2.3-2.3.9 1c.6.6 1.1 1.2 1.6 1.9l.1.2-.1.3V8c0 .5.3.9.7 1.2l.2.1.1.3c.3 1.1.3 2.2.3 3.3v1.5c0 .5-.1 1.1-.4 1.8l-.1.2-.2.1c-.4.2-.6.7-.6 1.1 0 .7.6 1.3 1.3 1.3.5 0 1-.3 1.2-.8l.1-.1c.3-.4.3-.7 0-1.1-.8-.8-.8-1.7-.8-2.5V8.6l1 1c.2.2.5.4.7.6.6.5 1.1.9 1.4 1.7l.1.2v.5c0 .7.6 1.3 1.3 1.3s1.3-.6 1.3-1.3c0-.8-.8-1.5-1.6-1.3l-.2.1-.2-.1c-1-.5-2.6-2.1-2.7-3.2 0-.2-.1-.4-.2-.5-.2-.6-.8-1-1.4-.9h-.2l-.2-.1c-.6-.3-1.1-.8-1.7-1.3-.3-.3-.7-.6-1-.8l-.6-.4L12.6 3c.5-.4.8-.7 1.2-1.1C14.9 1 16.1 1 17 2c3 2.9 6 5.8 9.2 9 1 1 1 2.3 0 3.3l-9.1 9.1c-.6.5-1.2.8-1.7.8zM11.2 6L9.8 7.5l-4.4 4.4c-.6.6-.6 1.1 0 1.7l9 9c.7.7 1 .7 1.7 0 3.1-3.1 6.2-6.1 9.1-9 .5-.5.5-1.1 0-1.6C22 8.8 19 5.8 16.1 3c-.5-.5-1-.5-1.6 0-.4.3-.8.7-1.2 1h-.1c.2.1.3.3.5.4.5.4.9.8 1.4 1 1-.1 2 .6 2.4 1.6.1.2.2.5.3.9 0 .4 1 1.6 1.9 2.1 1.5-.2 2.9 1 2.9 2.5 0 1.4-1.1 2.5-2.5 2.5s-2.5-1.1-2.5-2.5v-.3c-.2-.3-.4-.6-.8-.9v3c0 .8 0 1.2.5 1.7.7.7.8 1.7.2 2.5-.5 1-1.4 1.5-2.4 1.5-1.4 0-2.5-1.1-2.5-2.5 0-.8.4-1.6 1.1-2 .2-.4.3-.8.2-1.1v-1.6c0-1 0-1.9-.2-2.8-.7-.4-1.1-1.2-1.1-2v-.3c-.4-.5-.8-1-1.4-1.7z\" fill=\"#9d9d9d\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 31.2 25.5"
    }
  };
});