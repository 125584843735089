define("ember-svg-jar/inlined/pull-icon-5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path class=\"pull-icon-5_svg__ring\" d=\"M4.3 59.087C10.341 75.335 25.989 86.91 44.34 86.91c23.585 0 42.705-19.12 42.705-42.705C87.045 20.62 67.925 1.5 44.34 1.5c-18.035 0-33.458 11.179-39.719 26.986\" style=\"transform-origin:center\" fill=\"none\" stroke=\"#b4bfca\" stroke-width=\"3\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"/><g transform=\"translate(-7.506 -11.099)\" fill=\"none\" stroke=\"#929497\" stroke-width=\"3.333\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"><circle cx=\"45.838\" cy=\"39.943\" r=\"4.77\"/><circle cx=\"67.489\" cy=\"57.729\" r=\"4.77\"/><circle cx=\"45.838\" cy=\"69.291\" r=\"4.77\"/><path d=\"M45.838 44.712v19.809M45.838 44.712c2.433 7.325 7.214 12.544 16.88 13.377\"/></g>",
    "attrs": {
      "viewBox": "0 0 88.5 88.5",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});