define("ember-svg-jar/inlined/flash-success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.6 19.5c-.3 0-.6-.1-.8-.3l-4.9-3.8c-.5-.4-.6-1.2-.2-1.8.4-.5 1.2-.6 1.8-.2l3.8 3 5.2-7.7c.4-.6 1.2-.7 1.7-.4.6.4.7 1.2.4 1.7l-6 8.9c-.2.3-.5.5-.9.6h-.1z\" fill=\"#39aa56\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 30.8 23"
    }
  };
});