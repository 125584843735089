define("ember-svg-jar/inlined/icon-pullrequest", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Pull request event</title><circle cx=\"3.299\" cy=\"2.344\" r=\"1.699\"/><circle cx=\"3.299\" cy=\"12.801\" r=\"1.699\"/><circle cx=\"11.697\" cy=\"12.801\" r=\"1.699\"/><path d=\"M3.299 4.044v7.058M8.752.5S6.806 2.446 6.806 2.475h2.396s2.483-.152 2.483 2.462v6.143M8.752 4.436S6.806 2.49 6.806 2.461\"/>",
    "attrs": {
      "viewBox": "0 -1 16 17"
    }
  };
});