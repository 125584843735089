define("ember-svg-jar/inlined/icon-close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16.5 11.5l5-5c.3-.3.3-.8 0-1.1s-.8-.3-1.1 0l-5 5-5-5c-.3-.3-.8-.3-1.1 0s-.3.8 0 1.1l5 5-5 5c-.3.3-.3.8 0 1.1.2.2.4.2.6.2s.4-.1.6-.2l5-5 5 5c.2.2.4.2.6.2s.4-.1.6-.2c.3-.3.3-.8 0-1.1l-5.2-5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "8 0 14 22"
    }
  };
});