define("ember-svg-jar/inlined/signingithub-hover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#FFF\" d=\"M7.998 0a8 8 0 00-2.525 15.59c.396.074.545-.172.545-.385 0-.188-.01-.692-.01-1.358-2.227.481-2.697-1.074-2.697-1.074-.365-.925-.889-1.17-.889-1.17-.727-.496.055-.486.055-.486.805.057 1.227.825 1.227.825.711 1.222 1.871.868 2.328.665.07-.518.277-.869.508-1.071-1.777-.202-3.643-.888-3.643-3.953 0-.874.312-1.586.822-2.146-.084-.205-.356-1.019.078-2.12 0 0 .672-.213 2.199.82a7.638 7.638 0 014.007.002c1.524-1.034 2.195-.82 2.195-.82.438 1.102.163 1.915.079 2.117.513.561.823 1.273.823 2.146 0 3.073-1.871 3.75-3.651 3.946.288.25.54.736.54 1.484 0 1.067-.008 1.93-.008 2.192 0 .213.146.463.552.385A8.001 8.001 0 007.998 0\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "15.605",
      "viewBox": "0 0 16 15.605"
    }
  };
});