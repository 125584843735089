define("@ember-data/adapter/index", ["exports", "@ember-data/adapter/index-f54121ea", "@ember-data/adapter/build-url-mixin-18db8c8b"], function (_exports, _indexF54121ea, _buildUrlMixin18db8c8b) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "BuildURLMixin", {
    enumerable: true,
    get: function () {
      return _buildUrlMixin18db8c8b.B;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _indexF54121ea.A;
    }
  });
});