define("ember-svg-jar/inlined/push-icon-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#EDEEEE\" d=\"M32.748 65.249C14.83 65.249.253 50.67.253 32.75.253 14.831 14.83.252 32.748.252c17.92 0 32.499 14.579 32.499 32.498 0 17.919-14.579 32.499-32.499 32.499zm0-61.998c-16.264 0-29.495 13.233-29.495 29.498 0 16.266 13.231 29.499 29.495 29.499 16.266 0 29.499-13.233 29.499-29.499 0-16.265-13.233-29.498-29.499-29.498z\"/><path fill=\"#418793\" d=\"M46.663 32.552h-5.152v1.289c0 4.836-3.932 8.762-8.763 8.762-4.835 0-8.768-3.926-8.768-8.762s3.933-8.76 8.768-8.76c.37 0 .726.019 1.088.062v7.007l2.204-2.197 6.915-6.951.915-.905-.915-.909-6.915-6.932-2.204-2.201V18.675c-.362-.025-.725-.04-1.088-.04-8.396 0-15.207 6.819-15.207 15.207 0 8.387 6.812 15.211 15.207 15.211 8.389 0 15.209-6.824 15.209-15.211v-1.289h-1.294z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "65.5",
      "height": "65.5",
      "viewBox": "0 0 65.5 65.5"
    }
  };
});