define("ember-svg-jar/inlined/icon-restart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.5 5.768l-2.07 2.506M9.549 6.451l2.881 1.823m-.016-.395c0-3.873-2.705-6.379-5.981-6.379a5.933 5.933 0 104.446 9.861\"/>",
    "attrs": {
      "viewBox": "0 0 15 15"
    }
  };
});