define("ember-svg-jar/inlined/log.fold.open", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M.5 1.5l4.5 7 4.5-7z\" stroke=\"#777\" stroke-width=\".5\" fill=\"#999\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "height": "10",
      "width": "10",
      "viewBox": "0 0 10 10"
    }
  };
});