define("ember-svg-jar/inlined/icon-freebsd", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g stroke=\"#9ea3a8\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"><g fill=\"none\"><path d=\"M16.076 7.422a7 7 0 11-4.904-5.006M11.171 2.415a7.015 7.015 0 014.905 5.007\"/></g><path d=\"M11.219 4.688c.275.746.986 2.005 2.75 2.682a.771.771 0 00.793-.145c.553-.663 1.69-1.968 2.013-4.573a.771.771 0 00-.737-.763c-.716-.028-2.075.282-4.517 1.886a.773.773 0 00-.302.913z\" fill=\"#fff\"/><path d=\"M5.819 3.083c-.763-.506-1.867-1.038-3.474-1.268a.77.77 0 00-.776.723c-.037.636.184 1.786 1.33 3.772\" fill=\"none\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 18.062 17.764"
    }
  };
});