define("ember-svg-jar/inlined/lang-erlang", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M45.4 47.3c1-1.2 1.9-2.4 2.7-3.8-1.5-.8-3-1.5-4.5-2.3 0 .1-.1.1-.1.2-.8 1.2-1.6 2.3-2.7 3.2-1 .8-2.1 1.4-3.4 1.5-1.7.2-3.3-.1-4.7-1.2-.8-.6-1.3-1.4-1.7-2.3-.4-.9-.7-1.9-.8-2.9-.1-.8-.1-1.6-.2-2.4v-.2H47.1c.3 0 .2 0 .2-.2 0-.9 0-1.9-.1-2.8-.2-2-.8-3.8-2-5.5 0-.1-.1-.1-.1-.2h6c-3.3-6-9.7-10-17-10-7.3 0-13.7 4-17 10h6.1v.1c-1.5 1.9-2.4 4.1-2.8 6.5-.4 3.1-.1 6 1.2 8.8.6 1.2 1.3 2.3 2.2 3.3l.1.1h-6.7c3.3 6 9.7 10 17 10 7.3 0 13.7-4 17-10-2.2.1-5 .1-5.8.1zM30.6 31.5c.5-1.3 1.5-2.2 2.9-2.5.9-.2 1.7-.1 2.5.2 1.1.5 1.7 1.3 2 2.5.1.3.1.6.1 1v.1h-7.9c.1-.5.2-.9.4-1.3z\" opacity=\".8\" fill=\"#9a1b1f\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 61.2 61.9"
    }
  };
});