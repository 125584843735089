define("ember-svg-jar/inlined/push-icon-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#EDEEEE\" d=\"M32.752 65.249C14.832 65.249.253 50.671.253 32.75.253 14.83 14.832.251 32.752.251S65.251 14.83 65.251 32.75 50.672 65.249 32.752 65.249zm0-61.998c-16.266 0-29.499 13.233-29.499 29.499s13.233 29.499 29.499 29.499S62.251 49.017 62.251 32.75c0-16.266-13.233-29.499-29.499-29.499z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#726AB0\" d=\"M22.469 40.538c-.113-.121-.223-.334-.42-.25s-.111.301-.111.467v4.625h-.026c0 1.531 0 3.074.002 4.619.004.164-.06.389.105.479.232.131.348-.123.477-.25 1.459-1.449 2.903-2.928 4.376-4.367.387-.375.348-.633-.004-.98-1.477-1.443-2.929-2.898-4.399-4.343zM34.809 23.541c.749-.01 1.496-.002 2.245-.002.725-.002 1.446-.011 2.168.002.344.006.607-.095.834-.378a29.982 29.982 0 003.475-5.646c.234-.503.148-.744-.44-.738-1.519.013-3.04.006-4.551.001-.357 0-.621.101-.781.46-.924 2.01-1.968 3.964-3.271 5.766-.336.453-.103.541.321.535z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#726AB0\" d=\"M22.845 33.68c3.342-1.229 6.695-2.459 10.257-2.901.864-.118 1.742-.165 2.59.114.654.214 1.026.618 1.017 1.375-.029 1.398-.01 2.789-.01 4.181v13.227c0 1.059.004 1.059 1.09 1.061 1.072.002 2.148-.027 3.216.016.663.025.855-.227.855-.867-.026-3.062-.01-6.125-.01-9.188h-.028c0-3.043.038-6.078-.009-9.111-.037-1.834-.693-3.429-2.335-4.429-1.087-.663-2.294-.927-3.565-.984-2.677-.115-5.245.443-7.77 1.249-.969.299-.973.307-.975-.673a892.04 892.04 0 01.019-9.11c.006-.646-.193-.903-.854-.88a66.634 66.634 0 01-3.66.011c-.589-.003-.819.153-.815.806.027 5.15.019 10.312.022 15.461.004.968.037.98.965.642z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "65.5",
      "height": "65.5",
      "viewBox": "0 0 65.5 65.5"
    }
  };
});