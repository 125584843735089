define("ember-svg-jar/inlined/stage-failed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" stroke=\"#db4545\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"><path d=\"M4.541 4.484l6.032 6.032M10.573 4.484l-6.032 6.032\"/><circle cx=\"7.5\" cy=\"7.5\" r=\"7\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 15 15"
    }
  };
});