define("ember-svg-jar/inlined/icon-trash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.513 14.485H3.777l-.761-9.651h9.258zM2.002 2.897h11.287m-3.528 0V1.246A.763.763 0 009 .485H6.29a.763.763 0 00-.761.761v1.652m.635 4.149l.357 5.25m2.625-5.25l-.357 5.25\"/>",
    "attrs": {
      "viewBox": "0 0 15 15"
    }
  };
});