define("ember-svg-jar/inlined/icon-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"none\" stroke=\"#9d9d9d\" d=\"M7.159 1.035c.188-.38.495-.38.683 0l1.596 3.234c.188.38.685.741 1.105.802l3.569.519c.42.061.515.353.211.649L11.74 8.756c-.304.296-.494.88-.422 1.298l.61 3.555c.072.418-.177.599-.552.401l-3.192-1.678c-.375-.197-.99-.197-1.365 0L3.627 14.01c-.375.197-.624.017-.552-.401l.61-3.555c.072-.418-.118-1.002-.422-1.298L.678 6.24c-.304-.296-.209-.588.211-.649l3.569-.519c.42-.061.917-.422 1.105-.802l1.596-3.235z\"/>",
    "attrs": {
      "viewBox": "0 0 15 15"
    }
  };
});