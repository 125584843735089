define("ember-svg-jar/inlined/404-hills-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<image overflow=\"visible\" opacity=\".1\" width=\"1173\" height=\"495\" xlink:href=\"BEFA45F0C4EFA63F.png\" transform=\"scale(.24)\"/><path fill=\"#BBCAC6\" d=\"M275.011 102.099L245.136 64.12h-13.599L185.894 6.48h-79.288L83.963 41.12H56.652L6.48 102.099v10.007h268.531v-10.007\"/><path fill=\"#B4C0BF\" d=\"M101.003 30.779L90.214 49.96H64.831L41.867 81.083h76.82l13.574-16.74h-30.068l16.788-33.567z\"/><path fill=\"#CAD8D7\" d=\"M106.605 6.48l-7.28 11.114h78.819l46.753 58.751h12.353l22.771 25.754 14.99-.122-29.875-37.857h-13.599L185.894 6.48z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": "281.52",
      "height": "112.055",
      "viewBox": "0 0 281.52 112.055"
    }
  };
});