define("ember-svg-jar/inlined/icon-external-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.34 10.96v1.1c0 .55-.45 1-1 1H4.83c-.55 0-1-.45-1-1V6.55c0-.55.41-1 .91-1h.91m1.24 4.34l5.92-5.93m-3.9-.02h3.92v3.92\" fill=\"none\" stroke=\"#9d9d9d\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 17 17"
    }
  };
});