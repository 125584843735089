define("ember-svg-jar/inlined/feature-icon-platform", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.6 1c.4 0 .8.1 1.1.2l10.7 4.2-11.3 5.1c-.3.1-.7.2-1.2.2-.4 0-.8-.1-1-.2L1.7 6.2l11.6-5c.4-.1.8-.2 1.3-.2m0-1c-.6 0-1.2.1-1.6.3L.6 5.6c-.8.4-.8.9 0 1.2l11.8 4.7c.4.2.9.2 1.4.2.6 0 1.2-.1 1.6-.3l12-5.4c.8-.4.8-.9 0-1.3L16 .2c-.4-.1-.9-.2-1.4-.2zM25.2 14.7l1.2.5-11.3 5.1c-.3.1-.7.2-1.2.2-.4 0-.8-.1-1-.2L1.7 16l1.3-.6 8.8 3.5c.7.3 1.5.4 2 .4.9 0 1.7-.2 2.4-.5l9-4.1m-.1-1.1l-9.3 4.2c-.5.2-1.2.4-2 .4-.4 0-1.1 0-1.7-.3L3 14.3l-2.4 1c-.8.4-.8.9 0 1.2l11.8 4.7c.4.2.9.2 1.4.2.6 0 1.2-.1 1.6-.3l11.9-5.4c.8-.4.8-.9 0-1.3l-2.2-.8z\" fill=\"#ed8843\"/><path d=\"M25.2 19.6l1.2.5-11.3 5.1c-.3.1-.7.2-1.2.2-.4 0-.8-.1-1-.2L1.7 20.8l1.3-.5 8.8 3.5c.7.3 1.5.4 2 .4.9 0 1.7-.2 2.4-.5l9-4.1m-.1-1.1l-9.3 4.2c-.5.2-1.2.4-2 .4-.4 0-1.1 0-1.7-.3L3 19.2l-2.4 1c-.8.4-.8.9 0 1.2l11.8 4.7c.4.2.9.2 1.4.2.6 0 1.2-.1 1.6-.3l11.9-5.4c.8-.4.8-.9 0-1.3l-2.2-.8zM25.2 9.8l1.2.5-11.3 5.1c-.3.1-.7.2-1.2.2-.4 0-.8-.1-1-.2L1.7 11.1l1.3-.6 8.8 3.5c.7.3 1.5.4 2 .4.9 0 1.7-.2 2.4-.5l9-4.1m-.1-1.1L15.8 13c-.5.2-1.2.4-2 .4-.4 0-1.1 0-1.7-.3L3 9.4l-2.4 1c-.8.4-.8.9 0 1.2l11.8 4.7c.4.2.9.2 1.4.2.6 0 1.2-.1 1.6-.3l11.9-5.4c.8-.4.8-.9 0-1.3l-2.2-.8z\" fill=\"#ed8843\"/>",
    "attrs": {
      "viewBox": "0 0 28 27.7"
    }
  };
});