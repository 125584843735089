define("ember-svg-jar/inlined/icon-bitbucket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path class=\"icon-bitbucket_svg__header__svg-logo__icon--path--3\" d=\"M3.805 1.93a2 2 0 00-2 2.32l8.49 51.54a2.72 2.72 0 002.66 2.27h40.73a2 2 0 002-1.68l8.51-52.11a2 2 0 00-2-2.32zm35.75 37.25h-13l-3.52-18.38h19.67z\"/><path class=\"icon-bitbucket_svg__header__svg-logo__icon--path--4\" d=\"M61.475 20.8h-18.77l-3.15 18.38h-13l-15.35 18.23a2.71 2.71 0 001.75.66h40.74a2 2 0 002-1.68z\"/>",
    "attrs": {
      "version": "1.0",
      "viewBox": "0 0 66 60",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});