define("ember-svg-jar/inlined/open-chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"28\" cy=\"28\" r=\"28\" fill=\"#3EAAAF\"/><g clip-path=\"url(#open-chat_svg__clip0_217_14832)\"><path d=\"M16 19c0-1.655 1.345-3 3-3h18c1.655 0 3 1.345 3 3v13.5c0 1.655-1.345 3-3 3h-6.502L24.7 39.85a.757.757 0 01-.787.07.738.738 0 01-.413-.67V35.5H19c-1.655 0-3-1.345-3-3V19zm7.125 3.75c-.623 0-1.125.502-1.125 1.125S22.502 25 23.125 25h9.75c.623 0 1.125-.502 1.125-1.125s-.502-1.125-1.125-1.125h-9.75zm0 4.5c-.623 0-1.125.502-1.125 1.125s.502 1.125 1.125 1.125h5.25c.623 0 1.125-.502 1.125-1.125s-.502-1.125-1.125-1.125h-5.25z\" fill=\"#fff\"/></g><defs><clipPath id=\"open-chat_svg__clip0_217_14832\"><path fill=\"#fff\" transform=\"translate(16 16)\" d=\"M0 0h24v24H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "56",
      "height": "56",
      "viewBox": "0 0 56 56",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});