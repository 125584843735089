define("ember-svg-jar/inlined/500-hills-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<image overflow=\"visible\" opacity=\".1\" width=\"1382\" height=\"561\" xlink:href=\"6AB008E8BA7227E6.png\" transform=\"scale(.24)\"/><path fill=\"#DDC782\" d=\"M325.052 117.99L275.16 64.12h-33.615L185.894 6.48H96.599L73.956 41.12H46.645L6.48 118.112v10.008h318.572z\"/><path fill=\"#E2D5A8\" d=\"M95.996 6.48l-7.11 11.114h89.259l56.76 58.751h32.367l39.786 41.956 17.994-.311-49.892-53.87h-33.615L185.894 6.48z\"/><path fill=\"#D6BA6B\" d=\"M90.997 34.783L80.208 53.964H52.821l-34.974 63.148h76.82l19.579-48.765h-21.06l15.788-33.567z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": "331.68",
      "height": "128.235",
      "viewBox": "0 0 331.68 128.235"
    }
  };
});