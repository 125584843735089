define("ember-svg-jar/inlined/500-hills-shade-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#D6BA6B\" d=\"M186.234 11.69L176.226 0H51.494L0 25.842h202.272V11.69z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "202.271",
      "height": "25.842",
      "viewBox": "0 0 202.271 25.842"
    }
  };
});