define("ember-svg-jar/inlined/grey-blob", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<linearGradient id=\"grey-blob_svg__a\" gradientUnits=\"userSpaceOnUse\" x1=\"-48.351\" y1=\"151.358\" x2=\"531.776\" y2=\"151.358\" gradientTransform=\"rotate(-6.586 422.08 -206.385)\"><stop offset=\"0\" stop-color=\"#fff\" stop-opacity=\"0\"/><stop offset=\".437\" stop-color=\"#d7dde3\" stop-opacity=\".437\"/><stop offset=\".788\" stop-color=\"#bdc7d1\" stop-opacity=\".788\"/><stop offset=\"1\" stop-color=\"#b4bfca\"/></linearGradient><path d=\"M103.031 308.404s73.454 30.675 130.985-3.93c64.979-39.085 34.072-68.253 95.848-114.534s99.034-14.225 160.174-66.026 74.99-113.525 64.216-122.07c-10.774-8.545-26.324 14.224-82.687 34.711S361.704 29.659 279.654 69.89s-62.73 110.712-115.379 139.16S22.464 191.857 3.889 224.763s31.845 57.849 99.142 83.641z\" fill=\"url(#grey-blob_svg__a)\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 557.581 320.944"
    }
  };
});