define("ember-intercom-io/services/intercom", ["exports", "@ember/polyfills", "@ember/service", "@ember/object", "@ember/debug", "intercom", "@ember/runloop", "@ember/utils", "@ember/string", "@ember/object/evented", "@ember/object/computed"], function (_exports, _polyfills, _service, _object, _debug, _intercom, _runloop, _utils, _string, _evented, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const WarnOption = {
    id: 'ember-intercom-io.missing-data'
  };

  /**
   * Normalization function for converting intercom data to a consistent format.
   *
   * Changes:
   * - underscore keys
   * - convert dates to unix timestamps
   *
   * @param  {Object} data
   *
   * @private
   * @return {Object}
   */
  function normalizeIntercomMetadata(data) {
    let result = {};
    let val;
    Object.keys(data).forEach(key => {
      val = data[key];
      if ((0, _utils.typeOf)(val) === 'object') {
        result[(0, _string.underscore)(key)] = normalizeIntercomMetadata(val);
      } else {
        if ((0, _utils.typeOf)(val) === 'date') {
          val = val.valueOf();
        }
        if ((0, _utils.typeOf)(val) !== 'undefined') {
          result[(0, _string.underscore)(key)] = val;
        }
      }
    });
    return result;
  }
  var _default = _exports.default = _service.default.extend(_evented.default, {
    init() {
      this._super(...arguments);
      (0, _object.set)(this, 'user', {
        email: null,
        name: null,
        hash: null,
        user_id: null
      });
    },
    api: _intercom.default,
    user: null,
    /**
     * [description]
     * @return {[type]} [description]
     */
    _userHashProp: (0, _object.computed)('user', 'config.userProperties.userHashProp', function () {
      return (0, _object.get)(this, `user.${(0, _object.get)(this, 'config.userProperties.userHashProp')}`);
    }),
    _userIdProp: (0, _object.computed)('user', 'config.userProperties.userIdProp', function () {
      return (0, _object.get)(this, `user.${(0, _object.get)(this, 'config.userProperties.userIdProp')}`);
    }),
    _userNameProp: (0, _object.computed)('user', 'config.userProperties.nameProp', function () {
      return (0, _object.get)(this, `user.${(0, _object.get)(this, 'config.userProperties.nameProp')}`);
    }),
    _userEmailProp: (0, _object.computed)('user', 'config.userProperties.emailProp', function () {
      return (0, _object.get)(this, `user.${(0, _object.get)(this, 'config.userProperties.emailProp')}`);
    }),
    _userCreatedAtProp: (0, _object.computed)('user', 'config.userProperties.createdAtProp', function () {
      return (0, _object.get)(this, `user.${(0, _object.get)(this, 'config.userProperties.createdAtProp')}`);
    }),
    /**
     * Indicates the open state of the Intercom panel
     *
     * @public
     * @type {Boolean}
     */
    isOpen: false,
    /**
     * Indicates whether the Intercom boot command has been called.
     *
     * @public
     * @readonly
     * @type {Boolean}
     */
    isBooted: false,
    _hasUserContext: (0, _object.computed)('user', '_userEmailProp', '_userIdProp', function () {
      return !!(0, _object.get)(this, 'user') && (!!(0, _object.get)(this, '_userEmailProp') || !!(0, _object.get)(this, '_userIdProp'));
    }),
    /**
     * Reports the number of unread messages
     *
     * @public
     * @type {Number}
     */
    unreadCount: 0,
    /**
     * If true, will automatically update intercom when changes to user object are made.
     *
     * @type {Boolean}
     * @public
     */
    autoUpdate: true,
    /**
     * Hide the default Intercom launcher button
     *
     * @public
     * @type {Boolean}
     */
    hideDefaultLauncher: false,
    /**
     * @private
     * alias for appId
     * @type {[type]}
     */
    appId: (0, _computed.alias)('config.appId'),
    start(bootConfig = {}) {
      let _bootConfig = (0, _polyfills.assign)((0, _object.get)(this, '_intercomBootConfig'), bootConfig);
      this.boot(_bootConfig);
    },
    stop() {
      return this.shutdown();
    },
    /**
     * Boot intercom window
     * @param  {Object} [config={}] [description]
     * @public
     */
    boot(config = {}) {
      this._callIntercomMethod('boot', normalizeIntercomMetadata(config));
      this._addEventHandlers();
      this.set('isBooted', true);
    },
    /**
     * Update intercom data
     * @param  {Object} [config={}] [description]
     * @public
     */
    update(config = {}) {
      if (!this.get('isBooted')) {
        (false && (0, _debug.warn)('Cannot call update before boot', WarnOption));
        return;
      }
      let _hasUserContext = this.get('_hasUserContext');
      if (_hasUserContext) {
        this._callIntercomMethod('update', normalizeIntercomMetadata(config));
      } else {
        (false && (0, _debug.warn)('Refusing to send update to Intercom because user context is incomplete. Missing userId or email', WarnOption));
      }
    },
    /**
     * shutdown Intercom window
     * @public
     */
    shutdown() {
      this.set('isBooted', false);
      this._hasEventHandlers = false;
      this._callIntercomMethod('shutdown');
    },
    /**
     * Show intercom window
     * @public
     */
    show() {
      return this._wrapIntercomCallInPromise('show', 'show');
    },
    /**
     * hide intercom window
     * @public
     */
    hide() {
      return this._wrapIntercomCallInPromise('hide', 'hide');
    },
    toggleOpen() {
      this.get('isOpen') ? this.hide() : this.show();
    },
    /**
     * Opens the message window with the message list visible.
     *
     * @public
     * @return {Promise}
     */
    showMessages() {
      return this._wrapIntercomCallInPromise('showMessages', 'show');
    },
    /**
     * Opens the message window with the new message view.
     *
     * @public
     * @return {Promise}
     */
    showNewMessage(initialText) {
      return this._wrapIntercomCallInPromise('showNewMessage', 'show', initialText);
    },
    /**
     * You can submit an event using the trackEvent method.
     * This will associate the event with the currently logged in user and
     * send it to Intercom.
     *
     * The final parameter is a map that can be used to send optional
     * metadata about the event.
     *
     * @param {String} eventName
     * @param {Object} metadata
     * @public
     */
    trackEvent() {
      this._callIntercomMethod('trackEvent', ...arguments);
    },
    /**
     * A visitor is someone who goes to your site but does not use the messenger.
     * You can track these visitors via the visitor user_id. This user_id
     * can be used to retrieve the visitor or lead through the REST API.
     *
     * @public
     * @return {String} The visitor ID
     */
    getVisitorId() {
      return this.get('api')('getVisitorId');
    },
    /**
     * Private on hide
     * @private
     * @return {[type]} [description]
     */
    _onHide() {
      this.set('isOpen', false);
      this.trigger('hide');
    },
    /**
     * handle onShow events
     * @private
     */
    _onShow() {
      this.set('isOpen', true);
      this.trigger('show');
    },
    /**
     * Handle onUnreadCountChange Events
     * @param  {[type]} count [description]
     * @private
     */
    _onUnreadCountChange(count) {
      this.set('unreadCount', Number(count));
    },
    _addEventHandlers() {
      if (this._hasEventHandlers) {
        return;
      }
      this._callIntercomMethod('onHide', () => (0, _runloop.next)(this, '_onHide'));
      this._callIntercomMethod('onShow', () => (0, _runloop.next)(this, '_onShow'));
      this._callIntercomMethod('onUnreadCountChange', count => {
        this._onUnreadCountChange(count);
      });
      this._hasEventHandlers = true;
    },
    _wrapIntercomCallInPromise(intercomMethod, eventName, ...args) {
      return new Promise(resolve => {
        let isOpen = this.get('isOpen');
        if (eventName === 'show' && isOpen || eventName === 'hide' && !isOpen) {
          (0, _runloop.next)(this, resolve);
        } else {
          this.one(eventName, resolve);
        }
        this._callIntercomMethod(intercomMethod, ...args);
      });
    },
    _callIntercomMethod(methodName, ...args) {
      let intercom = this.get('api');
      intercom(methodName, ...args);
    },
    // eslint-disable-next-line ember/no-observers
    userDataDidChange: (0, _object.observer)('user.@each', function () {
      if (this.get('autoUpdate') && this.get('isBooted')) {
        let user = this.get('_computedUser');
        let appId = this.get('appId');
        let config = (0, _polyfills.assign)({
          app_id: appId
        }, user);
        this.update(config);
      }
    }),
    /**
     * Alias for computed user data with app-provided config values
     * @private
     * @type {[type]}
     */
    _computedUser: (0, _object.computed)('user.@each', 'user', '_userHashProp', '_userIdProp', '_userNameProp', '_userEmailProp', '_userCreatedAtProp', function () {
      (false && !(this.get('appId')) && (0, _debug.assert)('You must supply an "ENV.intercom.appId" in your "config/environment.js" file.', this.get('appId')));
      let obj = {};
      if (this.get('user')) {
        let userProps = Object.values((0, _object.get)(this, 'config.userProperties')),
          user = (0, _object.get)(this, 'user'),
          userKeys = Object.keys(user);
        userKeys.forEach(k => {
          if (!userProps.includes(k) && !obj.hasOwnProperty(k)) {
            obj[k] = user[k];
          }
        });
        obj.user_hash = (0, _object.get)(this, '_userHashProp');
        obj.user_id = (0, _object.get)(this, '_userIdProp');
        obj.name = (0, _object.get)(this, '_userNameProp');
        obj.email = (0, _object.get)(this, '_userEmailProp');
        if ((0, _object.get)(this, '_userCreatedAtProp')) {
          // eslint-disable-next-line
          obj.created_at = (0, _object.get)(this, '_userCreatedAtProp');
        }
      }
      return obj;
    }),
    _intercomBootConfig: (0, _object.computed)('config', 'user.@each', '_hasUserContext', 'hideDefaultLauncher', function () {
      let appId = (0, _object.get)(this, 'config.appId');
      let user = (0, _object.get)(this, '_computedUser');
      let _hasUserContext = (0, _object.get)(this, '_hasUserContext');
      let hideDefaultLauncher = (0, _object.get)(this, 'hideDefaultLauncher');
      (false && !(appId) && (0, _debug.assert)('You must supply an "ENV.intercom.appId" in your "config/environment.js" file.', appId));
      let obj = {
        app_id: appId
      };
      if (hideDefaultLauncher) {
        obj.hideDefaultLauncher = true;
      }
      if (_hasUserContext) {
        obj = (0, _polyfills.assign)({}, obj, user);
      }
      return normalizeIntercomMetadata(obj);
    })
  });
});