define('url/-anchor-url', ['exports', 'url/-base-url'], function (exports, _baseUrl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  class AnchorURL extends _baseUrl.default {
    constructor(url, base) {
      super(url);

      let doc = document.implementation.createHTMLDocument('');
      if (base) {
        let baseElement = doc.createElement('base');
        baseElement.href = base;
        doc.head.appendChild(baseElement);
      }
      let anchorElement = doc.createElement('a');
      anchorElement.href = url;
      doc.body.appendChild(anchorElement);

      if (anchorElement.href === '') {
        throw new TypeError('Invalid URL');
      }

      Object.defineProperty(this, '_urlObject', { value: anchorElement });
    }
  }
  exports.default = AnchorURL;
});