define("ember-svg-jar/inlined/icon-windows", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M1.2 8.6v-5l6.1-.8v5.8H1.2zm7.4-6l8.2-1.2v7.2H8.6v-6zm8.2 7.2V17l-8.2-1.2v-6h8.2zm-9.5 5.9l-6.1-.8v-5h6.1v5.8zm0-.2\" fill=\"none\" stroke=\"#9ba0a6\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 18.3 17.7"
    }
  };
});