define("ember-svg-jar/inlined/500-hills-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<image overflow=\"visible\" opacity=\".1\" width=\"973\" height=\"390\" xlink:href=\"423B8197D66693F1.png\" transform=\"translate(127.102) scale(.24)\"/><path fill=\"#DDC782\" d=\"M357.594 90.549L274.083 3h-63.448l-27.698 33.624h-18.492l-34.343 53.925z\"/><path fill=\"#D6BA6B\" d=\"M231.917 22.801h-25.486L187.94 47.95l-16.286-.744-26.372 41.165h23.143l14.024-24.823 16.788.024z\"/><path fill=\"#E2D5A8\" d=\"M274.083 3h-63.429l-6.283 7.625h62.83l76.871 79.924h13.522z\"/><image overflow=\"visible\" opacity=\".1\" width=\"1517\" height=\"309\" xlink:href=\"423B8197D66693F3.png\" transform=\"matrix(.24 0 0 .24 0 32.288)\"/><path fill=\"#DDC782\" d=\"M189.581 89.523l-25.98-20.778h-13.989l-23.981-29.977H80.667L6.48 89.779v10.008h351.114V89.523z\"/><linearGradient id=\"500-hills-right_svg__a\" gradientUnits=\"userSpaceOnUse\" x1=\"270.453\" y1=\"86.524\" x2=\"270.453\" y2=\"89.524\"><stop offset=\"0\" stop-color=\"#b5a339\" stop-opacity=\"0\"/><stop offset=\".408\" stop-color=\"#c2ac4d\" stop-opacity=\".1\"/><stop offset=\"1\" stop-color=\"#d6ba6b\"/></linearGradient><path fill=\"url(#500-hills-right_svg__a)\" d=\"M352.266 86.524h-168.98v3H357.62z\"/><path fill=\"#E2D5A8\" d=\"M122.253 44.537l23.377 29.97h16.184l18.581 14.985h9.146l-25.94-20.747h-13.989l-23.981-29.977H80.667l-8.628 5.769z\"/><path fill=\"#D6BA6B\" d=\"M42.167 89.492H18.97l50.537-36.563h23.197z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": "364.08",
      "height": "99.524",
      "viewBox": "0 0 364.08 99.524"
    }
  };
});