define("ember-svg-jar/inlined/lang-ruby", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g opacity=\".8\" fill=\"#eb1d26\"><path d=\"M36.7 21.1H22.2l7.2 19.7zM20.6 16.9l6.8-7.4H13.1l7.4 7.4zM17.7 18.3l-6.9-7-4.2 7zM29.3 11.8l-6.1 6.5h12.6zM38.7 17l7.4-7.4H31.2l7.4 7.4zM40.7 21.1h-.1l-8 19h.2l19.2-19zM18.7 21.2v-.1H6.6l19.6 19.1h.4zM41.6 18.3H52l-3.6-6.8z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 61.2 50.4"
    }
  };
});