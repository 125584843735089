define("ember-svg-jar/inlined/cloud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M31.917 10.741c-.27 0-.536.018-.798.048a6.646 6.646 0 00-8.201-5.671c-1.513-2.475-4.151-3.718-7.249-3.415-6.935.676-6.689 8.599-6.648 8.94a6.877 6.877 0 00-7.737 6.822c0 3.798 3.079 5.877 6.877 5.877h23.756c3.756 0 6.8-2.045 6.8-5.8a6.8 6.8 0 00-6.8-6.801z\" bx:origin=\"-0.034301 -0.07647\" opacity=\".4\" fill=\"none\" stroke=\"#010101\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"/>",
    "attrs": {
      "viewBox": "0 0 40 25",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:bx": "https://boxy-svg.com"
    }
  };
});