define("ember-svg-jar/inlined/icon-arrowtabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"none\" stroke=\"#9d9d9d\" d=\"M4.32.5l6.247 6.942L4.32 14.5\"/>",
    "attrs": {
      "viewBox": "0 0 15 15"
    }
  };
});