define("ember-svg-jar/inlined/icon-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Tag</title><path d=\"M12.86 6.94l-.7-4.64-4.58-.65L.65 8.58l5.29 5.29 6.92-6.93z\"/><path d=\"M10.3 4.04l1.23-1.23c1.17-1.17 3.07-1.17 4.24 0s1.17 3.07 0 4.24l-2.96 2.97-3.74 3.74c-.45.45-.45 1.18 0 1.63l.14.14c.45.45 1.18.45 1.63 0l.56-.56\"/><circle transform=\"rotate(-45.001 9.645 4.882)\" class=\"icon-tag_svg__st1\" cx=\"9.65\" cy=\"4.88\" r=\"1.23\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 17.3 17.3"
    }
  };
});