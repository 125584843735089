define("ember-svg-jar/inlined/twitter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#AAA6A3\" d=\"M17.473 1.681a7.152 7.152 0 01-2.059.564A3.594 3.594 0 0016.99.262a7.194 7.194 0 01-2.276.87 3.586 3.586 0 00-6.108 3.269A10.172 10.172 0 011.217.656a3.58 3.58 0 001.109 4.785 3.57 3.57 0 01-1.624-.447v.045a3.587 3.587 0 002.876 3.515 3.634 3.634 0 01-1.619.062 3.586 3.586 0 003.348 2.489 7.185 7.185 0 01-5.306 1.483 10.141 10.141 0 005.495 1.611c6.593 0 10.198-5.463 10.198-10.199 0-.156-.002-.31-.01-.464a7.27 7.27 0 001.789-1.855z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "17.473",
      "height": "14.199",
      "viewBox": "0 0 17.473 14.199"
    }
  };
});