define("ember-svg-jar/inlined/icon-megaphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"none\" stroke=\"#9D9D9D\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\" d=\"M116.883 113.382l-6.322 20.772c-.903 2.71 0 5.419.903 8.129 1.807 2.71 3.613 4.516 6.322 4.516l34.321 9.936c5.419 1.806 10.838-1.807 12.645-7.226h0l6.322-21.677\"/><path fill=\"none\" stroke=\"#9D9D9D\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\" d=\"M216.233 42.934h0L85.272 79.061c-.903 0-.903 5.419-.903 12.645s.903 12.645.903 12.645l130.961 36.127\"/><ellipse fill=\"none\" stroke=\"#9D9D9D\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\" cx=\"216.233\" cy=\"92.609\" rx=\"11.741\" ry=\"48.771\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "180.385",
      "height": "186.5",
      "viewBox": "64.931 4.8 180.385 186.5"
    }
  };
});