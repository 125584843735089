define("ember-svg-jar/inlined/icon-debug", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.474 7.606c0-.741-.562-1.344-1.254-1.344h-1.528l1.282-1.186c.277-.256.435-.627.435-1.018V2.36c0-.741-.562-1.344-1.254-1.344S10.9 1.619 10.9 2.36v1.082l-1.054.975c-.092-.09-.176-.193-.275-.271.04-.159.063-1.259.063-1.43 0-1.155-.958-2.091-2.141-2.091-1.182 0-2.141.936-2.141 2.091 0 .177.025 1.281.067 1.445-.098.078-.18.181-.272.27l-1.069-.989V2.36c0-.742-.562-1.345-1.254-1.345s-1.255.604-1.255 1.345v1.697c0 .39.158.76.435 1.017l1.281 1.187H1.787c-.692 0-1.254.603-1.254 1.344S1.095 8.95 1.787 8.95h1.598l-1.382 1.28a1.387 1.387 0 00-.434 1.017v1.697c0 .742.562 1.345 1.254 1.345s1.255-.603 1.255-1.345v-1.082l.807-.747c.676.79 1.599 1.278 2.619 1.278 1.012 0 1.93-.482 2.605-1.262l.79.731v1.082c0 .742.563 1.345 1.255 1.345s1.254-.603 1.254-1.345v-1.697c0-.389-.158-.759-.434-1.017l-1.381-1.28h1.627c.692 0 1.254-.603 1.254-1.344z\" fill=\"none\" stroke=\"#9d9d9d\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 15 15"
    }
  };
});