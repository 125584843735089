define("ember-svg-jar/inlined/customer-logos-engineyard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#eb252c\"><path d=\"M-452 376.9h8.5v2.6h-5.4v3.5h5.3v2.7h-5.3v3.7h5.4v2.7h-8.5zM-441.6 380.8h2.6v1.5c.5-.9 1.5-1.9 3.5-1.8 3.7 0 4.4 2.8 4.4 4.8v6.8h-2.7V386c0-1.7-.6-3-2.4-3-1.9 0-2.6 1.3-2.6 2.9v6.2h-2.7v-11.3h-.1zM-417.4 390.7c0 1.8-.3 3-1 4-1 1.3-2.9 2.3-5.2 2.3-3.5 0-5.2-2.1-5.6-3.8h3.1c.2.5.7.9 1.2 1.2.6.3 1.2.4 1.5.4 1.6 0 3.3-1.1 3.3-3.4v-.9c-.6 1-1.7 2-3.7 2-3.2 0-5.8-2.5-5.8-5.9 0-3.7 2.6-6 5.8-6 1.6 0 3 .7 3.7 1.8v-1.6h2.7v9.9zm-9.5-4.3c0 2 1.4 3.5 3.4 3.5 1 0 1.8-.3 2.4-1 .6-.6.9-1.4 1-2.4 0-.9-.3-1.8-.8-2.4-.5-.7-1.4-1.1-2.5-1.1-1.9 0-3.5 1.4-3.5 3.4zM-415.2 380.8h2.7v11.3h-2.7zM-410.3 380.8h2.5v1.5c.5-.9 1.5-1.9 3.5-1.8 3.7 0 4.4 2.8 4.4 4.8v6.8h-2.7V386c0-1.7-.6-3-2.4-3-1.9 0-2.6 1.3-2.6 2.9v6.2h-2.7v-11.3zM-396.1 387.3c.1 1.8 1.7 2.8 3.3 2.8 1.3 0 2.2-.6 2.7-1.5h2.8c-.5 1.3-1.3 2.2-2.3 2.8-.9.7-2.1 1-3.2 1-3.5 0-6-2.8-6-6s2.5-6 5.9-6c1.7 0 3.2.6 4.2 1.7 1.4 1.4 1.9 3.2 1.7 5.2h-9.1zm6.4-2c-.1-1.1-1.2-2.6-3.2-2.5-2 0-3.1 1.4-3.2 2.5h6.4zM-376.9 386.6l-4.6-9.7h3.3l2.8 6.9 2.9-6.9h3.3l-4.6 9.7v5.5h-3.1zM-358.5 392.1h-2.7v-1.6c-.7 1.3-2.2 2-3.8 2-3.6 0-5.7-2.8-5.7-6 0-3.6 2.6-6 5.7-6 2.1 0 3.3 1.1 3.8 2v-1.7h2.7v11.3zm-9.5-5.6c0 1.4 1 3.4 3.4 3.4 1.5 0 2.5-.8 3-1.8.3-.5.4-1 .4-1.6 0-.5-.1-1.1-.3-1.6-.5-1.1-1.5-2-3.1-2-2.2 0-3.4 1.8-3.4 3.6zM-356.2 380.8h2.5v1.5c.5-1 1.4-1.8 3.1-1.8v2.7h-.1c-1.9 0-2.9.9-2.9 2.8v6.1h-2.7l.1-11.3zM-338.3 392.1h-2.6v-1.6c-.5.8-1.6 2-3.9 2-3.5 0-5.7-2.7-5.7-6 0-3.7 2.6-6 5.7-6 2.2 0 3.4 1.2 3.8 1.8v-5.4h2.7v15.2zm-9.5-5.7c0 1.9 1.4 3.5 3.5 3.5 1 0 1.9-.4 2.5-1 .6-.6 1-1.5 1-2.5s-.4-1.8-1-2.4c-.6-.6-1.5-1-2.4-1-2.3-.1-3.6 1.6-3.6 3.4zM-418 377.1h2.5l2.1 2.1v-3.9c0-.8-.7-1.5-1.5-1.5-.4 0-.7.1-1 .3-.3-.8-1.1-1.4-2.1-1.4-1.2 0-2.2 1-2.2 2.2 0 1.2 1 2.2 2.2 2.2M-336.8 377.2h-.4v-.3h1.2v.3h-.4v1.4h-.4v-1.4zm1-.3h.5l.5 1.2.5-1.2h.5v1.7h-.4v-1.3l-.5 1.3h-.3l-.5-1.3v1.3h-.4v-1.7h.1z\"/></g>",
    "attrs": {
      "viewBox": "-452 372.7 118.2 24.3"
    }
  };
});