define("ember-svg-jar/inlined/icon-cronjobs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Cron job event</title><circle cx=\"10.982\" cy=\"5.518\" r=\"3.518\"/><path d=\"M10.869 3.361v2.157l1.359.881M7.391 5.364h-.686c-.295 0-.591.295-.591.65v1.558H4.755V6.014c0-.355-.236-.65-.591-.65H2.391c-.295 0-.591.295-.591.65v1.558h-.709c-.295 0-.591.295-.591.65v3.642c0 .355.236.65.591.65h8.865c.295 0 .591-.295.591-.65V9.016\"/>",
    "attrs": {
      "width": "15",
      "height": "15",
      "viewBox": "0 0 15 15"
    }
  };
});