define("ember-svg-jar/inlined/icon-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path class=\"icon-calendar_svg__st0\" d=\"M.861.5H14.22v14H.861zm13.252 4.061H1.182\"/><path class=\"icon-calendar_svg__st0\" d=\"M4.639 7.913c.178-.166.475-.359.893-.359.734 0 2.093 1.164-.906 3.906h2.297m1.295-3.905h2.425s-1.4 1.693-1.4 3.906\"/>",
    "attrs": {
      "viewBox": "0 0 15 15"
    }
  };
});