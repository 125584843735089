define("ember-svg-jar/inlined/pull-icon-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path class=\"pull-icon-1_svg__ring\" d=\"M84.245 59.132c-6.041 16.248-21.689 27.823-40.04 27.823C20.62 86.955 1.5 67.835 1.5 44.25c0-23.585 19.12-42.705 42.705-42.705 18.035 0 33.458 11.179 39.719 26.986\" style=\"transform-origin:center\" fill=\"none\" stroke=\"#b4bfca\" stroke-width=\"3\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"/><g transform=\"translate(-5.531 -17.143)\" fill=\"none\" stroke=\"#929497\" stroke-width=\"3.333\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"><circle cx=\"38.068\" cy=\"46.373\" r=\"4.722\"/><circle cx=\"38.068\" cy=\"75.428\" r=\"4.722\"/><circle cx=\"61.4\" cy=\"75.428\" r=\"4.722\"/><path d=\"M38.068 51.095v19.611M53.206 41.262s-5.417 5.417-5.417 5.472h6.667s6.889-.417 6.889 6.833v17.055M53.206 52.206s-5.417-5.417-5.417-5.472\"/></g>",
    "attrs": {
      "viewBox": "0 0 88.5 88.5",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});