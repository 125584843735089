define("ember-svg-jar/inlined/sync-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#sync-icon_svg__clip0_1928_13374)\"><path d=\"M20.835 13.28c0-6.196-4.328-10.206-9.57-10.206a9.493 9.493 0 107.114 15.778m5.794-8.949l-3.312 4.01 3.312-4.01zm-7.922 1.093l4.61 2.917-4.61-2.917z\" stroke=\"#3EAAAF\"/></g><defs><clipPath id=\"sync-icon_svg__clip0_1928_13374\"><path fill=\"#fff\" transform=\"translate(.973 .676)\" d=\"M0 0h24v24H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "25",
      "height": "25",
      "viewBox": "0 0 25 25",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});