define("ember-svg-jar/inlined/features-callouts-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#B8BABC\" d=\"M15.762 0C7.059 0 0 7.058 0 15.765c0 6.963 4.518 12.872 10.781 14.958.788.145 1.076-.342 1.076-.76 0-.375-.013-1.367-.021-2.682-4.386.953-5.31-2.113-5.31-2.113-.716-1.82-1.75-2.307-1.75-2.307-1.431-.979.108-.957.108-.957 1.582.111 2.416 1.623 2.416 1.623 1.405 2.41 3.688 1.715 4.586 1.311.143-1.02.55-1.713 1-2.107-3.5-.398-7.18-1.75-7.18-7.792 0-1.721.614-3.127 1.623-4.23-.162-.399-.703-2.002.155-4.17 0 0 1.324-.426 4.333 1.614a15.095 15.095 0 013.947-.529 15.13 15.13 0 013.946.529c3.011-2.04 4.33-1.614 4.33-1.614.86 2.168.32 3.771.158 4.169 1.01 1.104 1.619 2.51 1.619 4.23 0 6.057-3.684 7.387-7.196 7.778.565.488 1.069 1.449 1.069 2.922 0 2.107-.019 3.807-.019 4.324 0 .42.284.91 1.083.756 6.26-2.088 10.771-7.992 10.771-14.954C31.527 7.058 24.468 0 15.762 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
});