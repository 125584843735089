define("ember-svg-jar/inlined/icon-commit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"8.51\" cy=\"8.5\" r=\"3.5\" fill=\"none\" stroke=\"#9d9d9d\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"/><path d=\"M16.5 8.5h-4.49m-7 0H.5\" fill=\"none\" stroke=\"#9d9d9d\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 17 17"
    }
  };
});