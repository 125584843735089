define("ember-svg-jar/inlined/waiting-checkmark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path class=\"waiting-checkmark_svg__path\" fill=\"none\" stroke=\"#F1F1F1\" stroke-miterlimit=\"10\" d=\"M425.9 52.1h0c-2.2-2.6-6-2.6-8.3-.1l-42.7 46.2-14.3-16.4c-2.3-2.7-6.2-2.7-8.6-.1-1.9 2.1-2 5.6-.1 7.7l17.6 20.3.6.9c1.8 2 4.4 2.5 6.6 1.4.7-.3 1.4-.8 2-1.5.3-.3.5-.6.7-.9l46.3-50.1c2-2 2-5.3.2-7.4z\"/><circle class=\"waiting-checkmark_svg__path\" fill=\"none\" stroke=\"#F1F1F1\" stroke-width=\"10\" stroke-miterlimit=\"10\" cx=\"80.6\" cy=\"80.6\" r=\"62.1\"/><path class=\"waiting-checkmark_svg__path\" fill=\"none\" stroke=\"#F1F1F1\" stroke-width=\"6\" stroke-linecap=\"round\" stroke-miterlimit=\"10\" d=\"M113 52.8l-38.9 55.6-25.9-22\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 161.2 161.2"
    }
  };
});