define("ember-svg-jar/inlined/maintenance-hills-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<image overflow=\"visible\" opacity=\".1\" width=\"888\" height=\"291\" xlink:href=\"7F0562F1CC30193A.png\" transform=\"scale(.24)\"/><path fill=\"#8DAE7D\" d=\"M204.111 60.781L178.13 38.978h-13.988L140.162 9H95.196L9 60.781z\"/><path fill=\"#8DAE7D\" d=\"M204.111 60.781L178.13 38.977h-13.988L140.162 9H95.196L9 60.781v5.005h195.111z\"/><path fill=\"#B6C8A9\" d=\"M136.783 14.769l23.377 29.97h16.183l18.582 16.042h9.145l-25.94-21.804h-13.988L140.162 9H95.196l-9.749 5.769z\"/><path opacity=\".15\" fill=\"#49623D\" d=\"M46.688 59.724H23.492l57.542-36.563h23.196z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": "213.12",
      "height": "69.84",
      "viewBox": "0 0 213.12 69.84"
    }
  };
});