define('url/-node-url', ['exports', 'url/-base-url'], function (exports, _baseUrl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.URLSearchParams = undefined;


  const isFastBoot = typeof FastBoot !== 'undefined';
  var NodeURL = {};
  var NodeURLSearchParams = {};

  if (isFastBoot) {
    var _url = FastBoot.require('url');
    NodeURL = _url.URL;
    exports.URLSearchParams = NodeURLSearchParams = _url.URLSearchParams;

    if (!NodeURL) {
      NodeURL = class extends _baseUrl.default {
        constructor(url, base) {
          super(url);

          if (base) {
            url = _url.resolve(base, url);
          }

          url = _url.parse(url);

          if (url.href === '') {
            throw new TypeError('Invalid URL');
          }

          Object.defineProperty(this, '_urlObject', {
            value: url,
            configurable: true
          });
        }

        _setPart(part, value) {
          this._urlObject[part] = value;
          let url = _url.parse(_url.format(this._urlObject));
          Object.defineProperty(this, '_urlObject', {
            value: url,
            configurable: true
          });
        }
      };
    }
  }

  exports.URLSearchParams = NodeURLSearchParams;
  exports.default = NodeURL;
});